.PreViewEscala {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    width: 100%;
    height: 100%;

    border-radius: 8px;
    border: 0.1px solid #c0c0c0;
}

.PreViewEscala > .conceitos-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 10px;
    padding: 10px;
}

.PreViewEscala > .conceitos-container > .conceitos-title {
    text-align: start;
}

.PreViewEscala > .conceitos-container > .conceito {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 90%;
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 5px 10px 5px 5px;
    border: 0.1px solid #c0c0c0;
}

.PreViewEscala > .conceitos-container > .conceito:hover {
    background-color: #dedede;
}

.PreViewEscala > .conceitos-container > .conceito > .conceito-left {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 10px
}

.PreViewEscala > .conceitos-container > .conceito > .conceito-right {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px
}

.PreViewEscala > .conceitos-container > .conceito .conceito-delete {
    display: none;
}

.PreViewEscala > .conceitos-container > .conceito:hover .conceito-delete {
    display: flex;
    cursor: pointer;
}

.PreViewEscala > .conceitos-container > .conceito .conceito-edit {
    display: none;
}

.PreViewEscala > .conceitos-container > .conceito:hover .conceito-edit {
    display: flex;
    cursor: pointer;
}

.PreViewEscala > .conceitos-container > .conceito .conceito-title {
    font-size: 1.2rem;
    font-weight: 500;
}

/* .PreViewEscala > .conceitos-container > .conceito .conceito-valor {
    
} */

.PreViewEscala > .conceitos-container > .conceito .conceito-cor {
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 15px;
}

/* Numerico */

.PreViewEscala > .numerico-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 10px;
    padding: 10px;
}

.PreViewEscala > .numerico-container > .numerico-title {
    text-align: start;
}

.PreViewEscala > .numerico-container > .numerico {
    display: flex;
    flex-direction: row;

    width: 90%;
    padding: 5px 10px;
}

/* Estrela */

.PreViewEscala > .estrela-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 10px;
    padding: 10px;
}

