.projeto-chart-resumo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: transparent;
    max-width: 35vw;
    overflow: hidden;
    /* background-color: #c69454; */
}

.projeto-chart-resumo.clickable:hover {
    cursor: pointer;
    background-color: #D5D5D5;
    box-shadow: 1px 1px 10px 0px rgba(50, 50, 50, 0.2);
}

.projeto-chart-resumo > .line-name {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    height: 22px;
    text-align: center;
    width: 100%;
    cursor: default;
    white-space: normal;
}

.projeto-chart-resumo > .line-link {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 22px;
    max-height: 25px;
    min-height: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #000;
}

.projeto-chart-resumo > .line-gauge {
    display: flex;
    justify-content: center;
    width: 100%;
}