.ModalUploadResultadosAvaliacao {
    user-select: none;
}

.ModalUploadResultadosAvaliacao .titleModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 15px;

    font-size: 1.3rem;
    font-weight: 500;
    color: #606060;
    margin-bottom: 1.5rem;
    padding: 0px 7px;
    width: 100%;
}

.ModalUploadResultadosAvaliacao .titleModal > .titleLeft {
    display: flex;
    justify-content: start;
    align-items: center;

    gap: 15px;
}

.ModalUploadResultadosAvaliacao .bodyModal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    padding: 5px;

    flex: 1;
    width: 100%;
    overflow-y: auto;
}

.ModalUploadResultadosAvaliacao .bodyModal.loading {
    justify-content: center;
    align-items: center;
}

.ModalUploadResultadosAvaliacao .bodyModal .topHeader {
    display: flex;
    flex-direction: row;
    padding: 5px;

    width: 100%;
}

.ModalUploadResultadosAvaliacao .bodyModal .topHeader .subTitleContainer {
    display: flex;
    flex-direction: column;

    width: calc(100% - 100px);
    margin-bottom: 1.5rem;
}

.ModalUploadResultadosAvaliacao .bodyModal .topHeader .subTitleContainer .subTitle {
    font-size: 1.4rem;
    font-weight: 500;
    color: #3e3e3e;
    margin-bottom: 1.5rem;
}

.ModalUploadResultadosAvaliacao .bodyModal .topHeader .subTitleContainer .observacoes {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 10px;

    width: 100%;
}

.ModalUploadResultadosAvaliacao td {
    width: 100%;
}

.ModalUploadResultadosAvaliacao td.import {
    background-color: #f6ffe9;
}

.ModalUploadResultadosAvaliacao td .cellCheck {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    gap: 10px;
}