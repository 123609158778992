
.DiagnosticoOrientacao {
    height: calc(100vh - 20px);
    width: calc(100vw - 5px);
}

.DiagnosticoOrientacao .body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 5px;

    flex: 1;
    width: 100%;
    overflow-y: auto;

    gap: 10px;
}

.DiagnosticoOrientacao .body .empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center  !important; 
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
}

.DiagnosticoOrientacao .body .empty .orientacao-card {
    box-shadow: none    !important;
    border: 1px solid #333 !important;
    background-color: transparent !important;
}

.DiagnosticoOrientacao .loading {
    justify-content: center !important;
    align-items: center !important;
}

.orientacao-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    padding: 10px 20px;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(50, 50, 50, 0.1) 1px 1px 10px 0px;

    transition: box-shadow 0.3s;

    padding: 10px;
}

.orientacao-card .orientacao-card-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    gap: 20px;
}

.orientacao-card .orientacao-card-info {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
    padding: 0px 20px;
}

.orientacao-card .orientacao-card-info .orientacao-info-text {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.orientacao-card .orientacao-card-info .horizontal-line {
    flex: 1;
    height: 1px;
    background-color: #333;
}

.orientacao-card .orientacao-card-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.orientacao-card .orientacao-card-header .header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
}


@media screen and (max-width: 768px) {
    .orientacao-card .orientacao-card-header {
        flex-direction: column-reverse;
    }

    .orientacao-card .orientacao-card-body {
        flex-direction: column;
    }

    .orientacao-card .orientacao-card-header .header-buttons {
        justify-content: space-between;
        width: 100%;
    }
    
}