.conceitos-picker {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 10px;
    padding: 10px;
}


.conceitos-picker .conceito {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 90%;
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 5px 10px 5px 5px;
    border: 0.1px solid #c0c0c0;

    cursor: pointer;
}

.conceitos-picker .conceito:hover {
    background-color: #dedede;
}

.conceitos-picker .conceito .conceito-left {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 10px
}

.conceitos-picker .conceito .conceito-right {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px
}

.conceitos-picker .conceito .conceito-title {
    font-size: 1.2rem;
    font-weight: 500;
}

/* .avaliacao-questao-card > .conceitos-picker > .conceito .conceito-valor {
    
} */

.conceitos-picker .conceito .conceito-cor {
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 15px;
}

.conceitos-picker .selected {
    background-color: #dedede;
    outline: 2px solid darkgreen;
}

.conceitos-picker .disabled-conceito {
    cursor: default !important;
}

.StarPicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.StarPicker > .star-title {
    text-align: start;
    margin-bottom: 1rem;
}

.StarPicker > .star-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.StarPicker > .star-content .stars {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.StarPicker > .star-content .star {
    font-size: 3rem;
    color: #d0d0d0;
    cursor: pointer;
    flex-grow: 1;
    max-width: 50px;
    max-height: 100px;
    transition: transform 0.3s ease, color 0.2s ease;
    position: relative;
}

.StarPicker > .star-content .star svg {
    max-width: 100%;
}

.StarPicker > .star-content .star-disabled {
    cursor: default;
}

.StarPicker > .star-content .star.hover {
    color: #d9ba5e;
    transform: scale(1.3);
    z-index: 2;
}

.StarPicker > .star-content .star.unhovered-left {
    color: #d9ba5e;
    transform: translateX(-10px);
}

.StarPicker > .star-content .star.unhovered-right {
    transform: translateX(10px);
}

.StarPicker > .star-content .star.active {
    color: #EFB810;
}

.StarPicker > .star-content .star .star-value {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 2.5px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: rgb(55, 55, 55);
    z-index: 1;
}

.StarPicker > .star-content .star .star-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;   
    z-index: 1;
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}
.StarPicker > .star-content .star .star-items .star-delete {
    right: 0;
    font-size: 1rem;
    color: #d0d0d0;
    cursor: pointer;
    z-index: 1;
}

.StarPicker > .star-content .star .star-items .star-edit {
    left: 0;
    font-size: 1rem;
    color: #d0d0d0;
    cursor: pointer;
    z-index: 1;
}


