.Processo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 100vh;
    padding: 7px;
}

.Processo > .processo-header {
    margin: 0px 0px 0px 0px;
    padding: 10px;
    background-color: white;
    border: 0.3px solid lightgrey;
    border-radius: 8px;
    width: 100%;
    gap: 5px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}

.Processo > .processo-header > .header-left {

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

}
.Processo > .processo-header > .header-right {

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

.Processo > .processo-list {
    z-index: 0;
    flex: 1;
    overflow: auto;
    width: calc(100% + 14px);
    padding: 0 7px;
}