.PreviwerModeloRelatorio.folder{
    width: 100%;
    border-radius: 8px;
    border: 1px solid #D5D5D5;
    padding: 5px 5px 0px 5px;
}

/* ===== Conteúdo de elementos fixos ===== */

.PreviwerModeloRelatorio.folder > .exemplo-fixed {
    border-radius: 5px;
    box-shadow: 0px 0px 1px 0px #D5D5D5;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px 5px;
    color: #808080;
    font-weight: 700;
}

.PreviwerModeloRelatorio.folder > .exemplo-fixed > span {
    font-size: 0.8rem;
}

.PreviwerModeloRelatorio.folder > .exemplo-add-line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
}

.PreviwerModeloRelatorio.folder > .exemplo-add-line .line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
}

.PreviwerModeloRelatorio.folder > .exemplo-add-line .plus-icon {
    margin: 0 10px;
    color: #ccc; /* Ajuste a cor conforme necessário */
}

/* ===== Conteúdo de elementos dinâmicos provisorios ===== */

.PreviwerModeloRelatorio.folder > .add-provisional-item {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    min-height: 2rem;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #d0d0d0;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #a8a8a8;
    border: 1px solid #c0c0c0;
    color: #808080;
    font-weight: 700;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > span {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
}

/* text */

.PreviwerModeloRelatorio.folder > .add-provisional-item > .text {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: inline-block;
    word-wrap: break-word; /* Permite que o texto quebre a linha */
    word-break: break-word; /* Adicionalmente, quebra palavras longas */

    font-size: 0.6rem;
    font-weight: 300;
    color: black;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .text p {
    margin-bottom: 0px;
}

/* chart */

.PreviwerModeloRelatorio.folder > .add-provisional-item > .chart-demonstration {
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    padding: 2px;
    height: 6rem;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    background-color: #f0f0f0;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .chart-demonstration > .chart-icon {
    font-size: 2rem;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .chart-demonstration > .column {
    font-size: 0.8rem;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .chart-demonstration > .description {
    font-size: 0.6rem;
    text-align: center;
}

/* performance */

.PreviwerModeloRelatorio.folder > .add-provisional-item > .groupPerformance-demonstration {
    display: flex;
    flex-direction: column ;
    justify-content: start;
    align-items: center;
    padding: 2px;
    min-height: 6rem;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    background-color: #f0f0f0;
    width: 100%;

    font-size: 0.65rem;
    text-align: center;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .groupPerformance-demonstration .indicadoresGrid-demonstration {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px;
    width: 100%;
    height: 100%;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .groupPerformance-demonstration .indicadoresGrid-demonstration .indicador-demonstration {
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px;
    height: 4rem;
    max-height: 4rem;
    border-radius: 5px;
    background-color: #d0d0d0;
    border: 1px solid #c0c0c0;
    color: #808080;
}

.PreviwerModeloRelatorio.folder > .add-provisional-item > .groupPerformance-demonstration .indicadoresGrid-demonstration .indicador-demonstration .chart-icon {
    font-size: 1.5rem;
}

/* Results */

.PreviwerModeloRelatorio.folder > .add-provisional-item > .tableResults-demonstration {
    display: flex;
    flex-direction: column ;
    justify-content: start;
    align-items: center;
    padding: 2px;
    /* min-height: 6rem; */
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    background-color: #f0f0f0;
    width: 100%;

    font-size: 0.8rem;
}

.PreviwerModeloRelatorio.folder .table-container {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
}

.PreviwerModeloRelatorio.folder .table-container table {
    width: 100%;     /* Permite que a tabela se ajuste ao contêiner */
    min-width: 100%; /* Ocupa pelo menos 100% do contêiner */
    table-layout: auto; /* Permite que a largura das colunas se ajustem ao conteúdo */
}

.PreviwerModeloRelatorio.folder td {
    padding: 5px;
    text-align: center;
    font-size: 0.5rem;
    min-width: 50px; /* Define uma largura mínima razoável */
    max-width: 150px; /* Define uma largura máxima */
    word-wrap: normal; /* Alterado: não quebra palavras */
    word-break: normal; /* Alterado: não quebra palavras */
    white-space: normal; /* Permite múltiplas linhas */
    overflow-wrap: break-word; /* Quebra apenas quando necessário */
    hyphens: none; /* Evita hifenização */
}

.PreviwerModeloRelatorio.folder th {
    padding: 5px;
    text-align: center;
    font-size: 0.5rem;
    min-width: 50px;
    max-width: 150px;
    word-wrap: normal; /* Alterado: não quebra palavras */
    word-break: normal; /* Alterado: não quebra palavras */
    white-space: normal; /* Permite múltiplas linhas */
    overflow-wrap: break-word; /* Quebra apenas quando necessário */
    hyphens: none; /* Evita hifenização */
}

/* Divider */

.PreviwerModeloRelatorio.folder > .add-provisional-item > .content.box {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;

    border-radius: 5px;
    padding: 5px;
}

/* ===== Conteúdo de elementos dinâmicos já adicionados =====  */

.PreviwerModeloRelatorio.folder .item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0px 0px 1px 0px #D5D5D5;
    color: #808080;
    font-weight: 700;
    font-size: 0.8rem;
}

.PreviwerModeloRelatorio.folder .item span {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
}

.PreviwerModeloRelatorio.folder .item.edit {
    cursor: move;
}

.PreviwerModeloRelatorio.folder .item .edit-icon {
    position: absolute;
    top: 1px;
    right: 1px;
    display: none; /* Esconde o ícone por padrão */
    cursor: pointer; /* Indica que o ícone é clicável */
}

.PreviwerModeloRelatorio.folder .item:hover .edit-icon.hover-action {
    display: block; /* Mostra o ícone ao passar o mouse */
    background-color: white; /* Fundo branco */
    border-radius: 50%; /* Fundo redondo */
    padding: 5px; /* Espaçamento interno */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
}

.PreviwerModeloRelatorio.folder .item .delete-icon {
    position: absolute;
    top: 30px;
    right: 1px;
    display: none; /* Esconde o ícone por padrão */
    cursor: pointer; /* Indica que o ícone é clicável */
    color: #d74d4e
}

.PreviwerModeloRelatorio.folder .item:hover .delete-icon.hover-action {
    display: block; /* Mostra o ícone ao passar o mouse */
    background-color: white; /* Fundo branco */
    border-radius: 50%; /* Fundo redondo */
    padding: 5px; /* Espaçamento interno */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
}

/* Graficos */

.PreviwerModeloRelatorio.folder .item > .chart-demonstration {
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    padding: 2px;
    height: 6rem;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    background-color: #f0f0f0;
}

.PreviwerModeloRelatorio.folder .item > .chart-demonstration > .chart-icon {
    font-size: 2rem;
}

.PreviwerModeloRelatorio.folder .item > .chart-demonstration > .column {
    font-size: 0.8rem;
}

.PreviwerModeloRelatorio.folder .item > .chart-demonstration > .description {
    font-size: 0.6rem;
    text-align: center;
}

/* Performance */

.PreviwerModeloRelatorio.folder .item > .groupPerformance {
    display: flex;
    flex-direction: column ;
    justify-content: start;
    align-items: center;
    padding: 2px;
    min-height: 6rem;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    background-color: #f0f0f0;
    width: 100%;

    font-size: 0.65rem;
    text-align: center;
}

.PreviwerModeloRelatorio.folder .item > .groupPerformance .indicadoresGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px;
    width: 100%;
    height: 100%;
}

.PreviwerModeloRelatorio.folder .item > .groupPerformance .indicadoresGrid .indicador {
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px;
    height: 4rem;
    max-height: 4rem;
    border-radius: 5px;
    background-color: #d0d0d0;
    border: 1px solid #c0c0c0;
    color: #808080;
}

.PreviwerModeloRelatorio.folder .item > .groupPerformance .indicadoresGrid .indicador .chart-icon {
    font-size: 1.5rem;
}

/* Results */

.PreviwerModeloRelatorio.folder .item > .tableResults {
    display: flex;
    flex-direction: column ;
    justify-content: start;
    align-items: center;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    background-color: #f0f0f0;
    width: 100%;

    font-size: 0.7rem;
}

/* Divider */

.PreviwerModeloRelatorio.folder .item > .content.box {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;

    border-radius: 5px;
    padding: 5px;
    height: 1rem;

    transition: height 0.5s ease;
}

.PreviwerModeloRelatorio.folder .item:hover > .content.box {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;

    border-radius: 5px;
    padding: 10px;
    height: 3rem;
}

/* Texto */

.PreviwerModeloRelatorio.folder > .item > .item-wrapper {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #a8a8a8;
    min-height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.PreviwerModeloRelatorio.folder > .item > .item-wrapper span {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
}

.PreviwerModeloRelatorio.folder > .item > .item-wrapper > .text {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: inline-block;
    word-wrap: break-word; /* Permite que o texto quebre a linha */
    word-break: break-word; /* Adicionalmente, quebra palavras longas */

    font-size: 0.6rem;
    font-weight: 300;
    color: black;
}

.PreviwerModeloRelatorio.folder > .item > .text p {
    margin-bottom: 0px;
}

