.notice {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    padding: 3rem;

}

.listRelatorios {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
}