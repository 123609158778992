.plano-acao-quadro-card {
    width: calc(100% - 8px);
    padding: 10px;
    margin: 4px;
    background-color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    padding-bottom: 0px;
}

.plano-acao-quadro-card:hover {
    background-color: #F3F3F3 !important;
}

.icon {
    font-size: 1.5rem;
}

.icon.warning {
    color: #f2b809 !important;
}

.icon.atrasado {
    color: #f20909 !important;
    font-size: 1.2rem;
}

.icon.atrasado-concluido {
    color: #f2b809 !important;
    font-size: 1.2rem;
}

.icon.dependencias {
    font-size: .85rem;
    margin-left: .5rem;
    color: #878787;
}