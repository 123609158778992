.PainelPerformanceLineChart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PainelPerformanceLineChart > .controlsPerformance {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 5px 10px 0px 10px;
    background-color: 'transparent';
}

.PainelPerformanceLineChart > .controlsPerformance > .controlsPerformance__title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.PainelPerformanceLineChart > .controlsPerformance > .controlsPerformance__buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}

@media (max-width: 689px) {
    
    .PainelPerformanceLineChart > .controlsPerformance {
        margin: 3px 0px;
        padding: 5px 10px;
        background-color: #D5D5D5;
    }
}