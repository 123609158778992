.Questionario {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 100%;
    overflow: auto;
}

.Questionario > .loadingQuestionario {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Questionario > .emptyList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Questionario > .tabPage {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: 100%;
}

.Questionario > .tabPage > .title-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.Questionario > .tabPage > .title-container > .title {
    white-space: nowrap;
    margin-right: 10px;
    font-size: 1.2rem;
}

.Questionario > .tabPage > .title-container > .title-line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
}

.Questionario > .tabPage > .body {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    width: 100%;
    flex: 1;
    gap: 10px;
}

.Questionario > .tabPage > .body > .body-left {
    width: 45%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.Questionario > .tabPage > .body > .body-right {
    width: 55%;
    height: 100%;

    display: flex;
    flex-direction: column;
}