.boxGraficos {
    padding: 10px;
    width: 100%;
    transition: height 0.3s ease; /* Adiciona uma transição suave para a altura */
}

.boxGraficos > .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxGraficos .border {
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    overflow: hidden;
}

.boxGraficos > .graficosList {
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.boxGraficos > .graficosList .pesoPlanos {
    height: 100%;
    width: 400px;
    min-width: 400px;
    max-width: 100%;
}

.boxGraficos .emptyMarcos {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;
    background-color: #BCBCBC;
    border-radius: 8px;
}

@media (max-width: 680px) {

    .boxGraficos {
        height: 100% !important;
    }

    .boxGraficos > .graficosList {
        flex-direction: column;
    }

    .boxGraficos > .graficosList .pesoPlanos {
        height: 15rem !important;
    }
}


