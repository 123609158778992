.IndicadorReferenciaCard {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    padding: 10px 10px;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(50, 50, 50, 0.1) 1px 1px 10px 0px;
    width: 100%;

    transition: box-shadow 0.3s;
}

.IndicadorReferenciaCard:hover {
    box-shadow: rgba(50, 50, 50, 0.25) 1px 1px 10px 0px;
}

.IndicadorReferenciaCard.disabled {
    background-color: #f5f5f5;
    box-shadow: none;
    cursor: not-allowed !important;
}

.IndicadorReferenciaCard.disabled:hover {
    box-shadow: none;
}

.IndicadorReferenciaCard .lineCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.IndicadorReferenciaCard .infos {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    width: 40%;
}

.IndicadorReferenciaCard .infos .groupName {
    flex: 1;
    height: 25px;
    font-style: italic;
}

.IndicadorReferenciaCard .infos .iconButton {
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.IndicadorReferenciaCard .infos .iconButton.alertIcon {
    opacity: 1;
    animation: pulse 1.5s infinite;
}

.IndicadorReferenciaCard:hover .infos .iconButton {
    opacity: 1;
}

.IndicadorReferenciaCard .infos .iconDelete:hover {
    font-size: 1.55rem;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.IndicadorReferenciaCard .lineCard.infosIndicador {
    border-radius: 8px;
    background-color: #e3e3e3;
    padding: 5px;

    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.IndicadorReferenciaCard .infosIndicador {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.IndicadorReferenciaCard .infosIndicador .labelInfo {
    font-size: 0.7rem;
    font-weight: bold;
}

.IndicadorReferenciaCard .infosIndicador .value {
    font-size: 1rem;
}

.IndicadorReferenciaCard .lineCard.meta {
    padding-top: 1rem;
}

.IndicadorReferenciaCard .meta .meta {
    gap: 10px;
}

.IndicadorReferenciaCard .lineCard.save {
    padding-top: 1rem;
    justify-content: end;
    padding-bottom: 0.3rem;
}

.IndicadorReferenciaCard .lineCard.configFaixas {
    flex-direction: column;
    gap: 5px;
    padding-top: 1rem;
}

.IndicadorReferenciaCard .configFaixas .configFaixa {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 32px;
}

.IndicadorReferenciaCard .configFaixas .configFaixa .colorName {
    height: 32px;
    width: 30%;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    padding: 1px 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: #c3c3c3 1px solid;
}

.IndicadorReferenciaCard .configFaixas .configFaixa .valores {
    height: 32px;
    border-radius: 5px;
    background-color: #f0f0f0;
    padding: 10px;
    width: calc(70%);

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;

    border: #c3c3c3 1px solid;
} 

.IndicadorReferenciaCard  .input-with-unit {
    position: relative;
    display: flex;
    align-items: center;
}

.IndicadorReferenciaCard .unit-indicator {
    position: absolute;
    right: 8px;
    color: #777;
    pointer-events: none;
}

.IndicadorReferenciaCard .form-control-xsm {
    padding-right: 25px !important; /* Make room for the unit */
}