.DiagnosticoResultadoPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 100vh;
    padding: 7px;
}

.DiagnosticoResultadoPage > .header {
    margin: 0px 0px 0px 0px;
    padding: 10px;
    background-color: white;
    border: 0.3px solid lightgrey;
    border-radius: 8px;
    width: 100%;
}

.DiagnosticoResultadoPage > .header > .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
}

.DiagnosticoResultadoPage > .header > .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
}

.DiagnosticoResultadoPage > .body {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 69px);
    padding: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px lightgrey;
    border-radius: 8px;
}

.DiagnosticoResultadoPage > .body > .listTiposBody {
    height: 3rem;
    width: 100%;
}

.DiagnosticoResultadoPage > .body > .graficosBody {
    height: calc(100% - 3rem); /* Allow to grow and take remaining space */
    width: 100%;
}