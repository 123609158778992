.box-orcamento {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

 .box-orcamento > .box-orcamento-total {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    max-width: 100%;
    overflow: auto;
}

.box-orcamento > .box-orcamento-total > .celula {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 5px 25px 5px 10px;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    background-color: rgb(213, 213, 213);
}

.box-orcamento > .box-orcamento-total > .celula > .celula-valor{
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
}