.ModuleRelatorio.modulo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #808080;
    background-color: #ccc;
    padding: 10px 5px;
    cursor: pointer;
    width: 7rem;
    height: 7rem;
    user-select: none;
}

.ModuleRelatorio.modulo.selected {
    box-shadow: 0px 0px 5px 0px #D5D5D5;
    background-color: #a5a5a5;
    color: #707070;
}

.ModuleRelatorio.modulo:hover {
    box-shadow: 0px 0px 5px 0px #D5D5D5;
}