.CardIndicadorPainel {
    display: flex;
    flex-direction: column;
    height: 20vh;
    max-height: 200px;
    min-height: 120px;
    width: 60%;
    min-width: 60%;
    max-width: 300px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.CardIndicadorPainel > .headerCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;
    background-color: #d5d5d5;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 10px;
}

.CardIndicadorPainel > .contentCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    padding: 0px 10px;
    width: 100%;
    background-color: #f0f3f4;
}

.CardIndicadorPainel > .contentCard .contentCardTitle {
    font-size: 12px;
    color: #585858;
    text-align: center;
    margin-bottom: 5px;
}