.ConfigEstrutura {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    padding: 5px;
}

/* Observações e avisos */

.ConfigEstrutura > .observation {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.ConfigEstrutura > .observation > span {
    /* display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5px; */
}

.ConfigEstrutura > .observation .blocked {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;

    background-color: #d74d4e;
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: default;
}

/* Definição das colunas da estrutura */

.ConfigEstrutura > .content {
    width: 100%;
}

.ConfigEstrutura > .content .line-colunas {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: auto;
    max-width: calc(100% - 180px);
}

.ConfigEstrutura > .content .line-colunas > .coluna {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;

    height: 38px;
    width: auto;
    padding: 5px 10px;
    border-radius: 8px;

    background-color: #808080;
    color: white;
    white-space: nowrap;

    cursor: pointer;
    transition: all 0.5s ease;
}

.ConfigEstrutura > .content .line-colunas > .coluna.selected {
    background-color: #d74d4e;
}

/* Exemplo de uso da tabela */

.ConfigEstrutura > .exemplo {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    margin: 30px 0px 10px 0px;
}

.ConfigEstrutura > .exemplo > .title {
    font-size: 1.2rem;
    font-weight: bold;
    font-style: italic;
}

.ConfigEstrutura > .exemplo .table-content {
    width: 100%;
    border-collapse: collapse;
    margin: 0px 0px 20px 0px;
    font-size: 1rem;
    text-align: left;
    overflow-x: auto;
}

.ConfigEstrutura > .exemplo .table-content table {
    border-radius: 8px; 
    overflow: hidden; 
    border-collapse: separate; 
    border-spacing: 0;
    width: 100%;
}

.ConfigEstrutura > .exemplo > .table-content th,
.ConfigEstrutura > .exemplo > .table-content td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    width: auto;
    white-space: nowrap;
}

.ConfigEstrutura > .exemplo > .table-content th {
    background-color: #f4f4f4;
    font-size: 0.7rem;
    font-weight: 500;
}

.ConfigEstrutura > .exemplo > .table-content tr:nth-child(even) {
    background-color: #f9f9f9;
}

.ConfigEstrutura > .exemplo > .table-content tr:hover {
    background-color: #f1f1f1;
}

.ConfigEstrutura > .exemplo > .table-content th:first-child,
.ConfigEstrutura > .exemplo > .table-content td:first-child {
    border-left: none;
}

.ConfigEstrutura > .exemplo > .table-content th:last-child,
.ConfigEstrutura > .exemplo > .table-content td:last-child {
    border-right: none;
}

@media screen and (max-width: 600px) {
    .ConfigEstrutura > .exemplo > .table-content table {
        width: 100%;
    }

    .ConfigEstrutura > .exemplo > .table-content th,
    .ConfigEstrutura > .exemplo > .table-content td,
    .ConfigEstrutura > .exemplo > .table-content tr,
    .ConfigEstrutura > .exemplo > .table-content thead,
    .ConfigEstrutura > .exemplo > .table-content tbody {
        all: revert;
    }

    .ConfigEstrutura > .exemplo .table-content table {
        border-radius: 8px; 
        overflow: hidden; 
        border-collapse: separate; 
        border-spacing: 0;
        width: 100%;
    }
    
    .ConfigEstrutura > .exemplo > .table-content th,
    .ConfigEstrutura > .exemplo > .table-content td {
        padding: 12px 15px;
        border: 1px solid #ddd;
        width: auto;
        white-space: nowrap;
    }
    
    .ConfigEstrutura > .exemplo > .table-content th {
        background-color: #f4f4f4;
        font-size: 0.6rem;
        font-weight: 500;
    }
}

/* Banner para adicionar Coluna */

.ConfigEstrutura > .content-banner {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;

    width: 100%;
    flex: 1;
}

.questionarios-container {
    position: relative;
}

.ConfigEstrutura > .content-banner .collapse-content {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: white;
    z-index: 10;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow: hidden;
    border-radius: 8px;
    padding: 10px 10px 10px 10px;
}

.ConfigEstrutura > .content-banner .collapse-content .headerClose {
    width: calc(100% + 20px);
    height: 2rem;
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -10px -10px 10px -10px;
    padding: 0px 10px;
}
