.graphic-meta-realizado {
    position: relative; 
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #ededed;
    cursor: help;
    /* margin-top: 16px; */
    color: white;
    font-weight: bold;
}

.graphic-meta-realizado > .graphic-meta {
    position: absolute;
    min-width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    border: none;
    background-color: transparent;
    border-right: 2px solid #4d4d4d;
  }

.graphic-meta-realizado > .graphic-realizado {
    position: absolute;
    min-width: fit-content;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    background-color: transparent;
    font-size: 12px;
}

.graphic-meta-realizado > .graphic-realizado > .realizado-text{
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Sombra para destacar o texto */
}

.animateWidthMetaRealizado {
    animation-name: widthAnimation;
    animation-duration: 1s; /* Ajuste a duração conforme necessário */
}

@keyframes widthAnimation {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }