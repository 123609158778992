/* ProgressBar.css */
@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .progress-bar-100 {
    animation: pulse 1s 2; /* Animação de pulsar */
  }