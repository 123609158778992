.boxDescricao {
    padding: 10px;
    width: 100%;
    transition: height 0.3s ease; /* Adiciona uma transição suave para a altura */
}

.boxDescricao > .loading {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxDescricao .fatoresSwot {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 10rem;
    overflow-y: auto;
}

.boxDescricao .fatoresSwot .aviso {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BCBCBC;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 10px;
    font-size: 15px;
    height: 10rem;
    margin: 10px;
}

.boxDescricao .perspectiva {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    background-color: #BCBCBC;
    border-radius: 8px;
}

.boxDescricao .emptyVinculo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;
    background-color: #BCBCBC;
    border-radius: 8px;
}