/* Header */
.ModalDownloadImages__header {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #505050;
    margin-bottom: 10px;
}

/* Body */
.ModalDownloadImages__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 120px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    font-weight: bold;
    color: #656565;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #f0f0f0;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .count {
    font-size: 14px;
    font-weight: bold;
    transition: transform 0.3s ease;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos .count.increase {
    transform: scale(1.03); /* Aumenta ligeiramente o tamanho */
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .message {
    display: flex;
    align-items: center;
    transition: color 0.3s ease, transform 0.3s ease;
    font-size: 14px;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .message:hover {
    transform: scale(1.01);
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .all:hover {
    color: #59a63d;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .notAll:hover {
    color: #d74d4e;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .message > .fa-icon {
    transition: transform 0.3s ease;
}

.ModalDownloadImages__body > .ModalDownloadImages__infos > .message:hover > .fa-icon {
    transform: rotate(-15deg);
}

.ModalDownloadImages__body > .ModalDownloadImages__list {
    display: flex;
    justify-content: start;
    align-items: center;
    overflow-x: auto;
    max-height: 100%;
    max-width: 100%;
    padding: 5px 0px;
    gap: 15px;
}

.ModalDownloadImages__body > .ModalDownloadImages__list .image {
    border-radius: 5px;
    padding: 10px;
    border: 2px solid #ffffff;
}

.ModalDownloadImages__body > .ModalDownloadImages__list .image.selected {
    border-radius: 5px;
    border: 1px solid #858585;
    background-color: rgb(245, 245, 245, 0.3);
}

.ModalDownloadImages__body > .ModalDownloadImages__checks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.ModalDownloadImages__body > .ModalDownloadImages__checks > .checkbox {
    color: #858585;
    font-size: 0.8rem;
}

.ModalDownloadImages__body > .ModalDownloadImages__checks > .checkbox.selected {
    color: #333333;
}

/* Footer */
.ModalDownloadImages__footer {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    gap: 10px;
}