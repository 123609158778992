.relatorio-grafico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 5px;
    height: 200px;
    border-radius: 8px;
    border: 1px solid #bcbcbc;
}

.relatorio-grafico .relatorio-grafico-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.relatorio-grafico .relatorio-grafico-content .miniLoading {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 3px;
}