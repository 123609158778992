.recomendacoes>.contentRecomendacoes .recomendacao {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
    width: 100%;
}

.recomendacoes>.contentRecomendacoes .recomendacao .descricao {
    padding: 10px;
    margin: 5px 0px;
    border-radius: 8px;
    width: 100%;
    border: 0.3px solid #ccc;
}

.recomendacoes>.contentRecomendacoes .recomendacao .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.recomendacoes>.contentRecomendacoes .recomendacao .actions > .buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
}