.objetivoCardChart {
    width: 100%;
    height: 100%;
    color: black;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #c7c7c7;
}

.objetivoCardChart:hover {
    box-shadow: 0px 0px 5px #c7c7c7;
}

.objetivoCardChart.selected {
    background: linear-gradient(to bottom, #d2d2d2, #ececec, #f0f0f0, #f7f7f7);
    box-shadow: 0px 0px 5px #b8b8b8;
}

.objetivoCardChart > .title {
    font-size: 0.8rem;
    font-weight: 600;
    height: 2.5rem;
    max-height: 2.5rem;
    width: 100%;
    text-align: center;
}

.objetivoCardChart > .footer {
    position: absolute; /* Define a posição do elemento como absoluta em relação ao contêiner pai */
    bottom: 5px; /* Posiciona o elemento na parte inferior do contêiner pai */
    width: calc(100% - 20px); /* Ocupa 100% da largura do contêiner pai */
    height: 1rem; /* Define a altura do elemento */
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.nonPermission {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nonPermission > .circle {
    min-width: 60px;
    width: 60px;
    min-height: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ffc107;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 2rem;
}