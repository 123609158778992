.ModalPeriodoGrupoIndicadores .titleModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 15px;

    font-size: 1.3rem;
    font-weight: 500;
    color: #606060;
    margin-bottom: 1.5rem;
    padding: 0px 7px;
    width: 100%;
}

.ModalPeriodoGrupoIndicadores .titleModal > .titleLeft {
    display: flex;
    justify-content: start;
    align-items: center;

    gap: 15px;
}

.ModalPeriodoGrupoIndicadores .titleModal > .titleRight {
    display: flex;
    justify-content: end;
    align-items: center;

    gap: 15px;
}

.ModalPeriodoGrupoIndicadores .titleModal .editName {
    cursor: pointer;
}

.ModalPeriodoGrupoIndicadores .titleModal .editName:hover {
    scale: 1.05;
}

.ModalPeriodoGrupoIndicadores .bodyModal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    padding: 0px 7px;

    flex: 1;
    width: 100%;
    overflow-y: auto;
    position: relative; /* Adicionado para controle de layout */
}

.ModalPeriodoGrupoIndicadores .bodyModal .labelBody {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0 5px 0;
    margin-bottom: 5px;

    position: sticky; /* Adicionado para fixar no topo */
    top: 0; /* Fixa no topo do .bodyModal */
    background-color: white; /* Garante que o fundo seja visível */
    z-index: 1; /* Garante que fique acima do conteúdo rolável */
}

.ModalPeriodoGrupoIndicadores .bodyModal .labelBody .title {
    white-space: nowrap;
    margin-right: 10px;

    font-size: 0.9rem;
    font-weight: 500;
}

.ModalPeriodoGrupoIndicadores .bodyModal .labelBody .title-line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
}

.ModalPeriodoGrupoIndicadores .bodyModal .listInputName {
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow-x: auto;

    width: 100%;
    max-width: 100%;
    min-height: 5rem;
}

.ModalPeriodoGrupoIndicadores .bodyModal .listIndicadores {
    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 100%;
    max-width: 100%;

    gap: 10px;
    padding-bottom: 5px;
}

.ModalPeriodoGrupoIndicadores .bodyModal .emptyList {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    flex: 1;

    font-size: 1.2rem;
    font-weight: 500;
    color: #606060;
}