.criacaoRelatorio {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    border-radius: 8px;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.criacaoRelatorio > .top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.criacaoRelatorio > .top > .listaModelos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    max-width: calc(100% - 330px);
    overflow-x: auto;
    white-space: nowrap;
}

.criacaoRelatorio > .top > .isEditing {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #e3e3e3;
    padding: 5px 10px;
    border-radius: 8px;
    height: 38px;
}

.criacaoRelatorio > .top > .isEditing > .animated-ellipsis::after {
    content: '';
    display: inline-block;
    animation: ellipsisAnimation 1.2s steps(4, end) infinite;
    min-width: 1em;
}

@keyframes ellipsisAnimation {
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '';
    }
}

.criacaoRelatorio > .bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: calc(100% - 50px);
    padding-top: 5px;
}

.criacaoRelatorio > .bottom > .editModel {
    height: 100%;
    width: 100%;
}

.criacaoRelatorio > .bottom > .editModel > .editModel-row {
    height: 100%;
    width: calc(100% + 25px);
}

.modulos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 5px;
    position: relative;
    overflow: visible;
}

.modulos > .grid_modulos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}

.modulos .collapse-content {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: end;
}

.modulos .grid_modulos {
    transition: transform 0.3s ease-in-out;
}

.modulos .collapse.show .grid_modulos {
    transform: translateY(0);
}

.modulos .collapse:not(.show) .grid_modulos {
    transform: translateY(100%);
}

.modulos .buttons-model {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.criacaoRelatorio > .bottom > .viewModel {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.criacaoRelatorio > .bottom > .modelNotSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.criacaoRelatorio > .bottom .graficoItem {
    border-bottom: 0.5px solid lightgrey;
    padding-bottom: 10px;
}

.modelo {
    border-radius: 8px;
    background-color: #808080;
    font-size: bold;
    color: white;
    padding: 5px 10px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addGrupoGrafico {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    margin-bottom: 1rem;
}

.addGrupoGrafico > .titulo {
    margin-bottom: 0.5rem;
}

.addGrupoGrafico > .content {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}