.historico {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
}


.historico .loading-historico {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.historico .header-historico-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
}

.historico .header-historico-content .header-historico-title {
    font-size: 18px;
    font-weight: 500;
    color: rgb(60, 60, 60);
}

.historico .historico-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 250px;
}

.historico .historico-content .historico-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 16px;
    color: rgb(69, 69, 69);
    font-weight: 500;
}

.historico .historico-content .historico-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid rgb(240, 240, 240);
}

.historico .historico-content .historico-item .historico-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.historico .historico-content .historico-item .historico-item-content .historico-item-usuario {
    flex: 1;
}

.historico .historico-content .historico-item .historico-item-content .historico-item-data {
    text-align: center;
    font-size: 12px;
    color: rgb(69, 69, 69);
    font-weight: 500;
}

.historico .historico-content .historico-item .historico-item-texto {
    padding-top: 10px;
    font-size: 16px;
    color: rgb(69, 69, 69);
    font-weight: 400;
}