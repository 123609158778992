.HistoricoAvaliacaoModal {
    width: 80vw !important;
    height: 80%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px !important;
    overflow: hidden !important;
}

.HistoricoAvaliacaoModal .ModalHeader {
    height: 30px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.HistoricoAvaliacaoModal .ModalHeader .title {
    width: calc(100% - 30px);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
}

.HistoricoAvaliacaoModal .ModalHeader .close {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0px;
    user-select: none;
}

.HistoricoAvaliacaoModal .ModalBody {
    width: 100%;
    max-height: calc(100% - 35px);
    min-height: calc(100% - 35px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
}


.HistoricoAvaliacaoModal .ModalBody .historico {
    display: flex;
    flex-direction: column;
    margin-top: unset !important;
    margin-bottom: unset !important;
    flex: 1;
    width: 100%;
    min-height: 100% !important;
    max-height: 100% !important;
}


.HistoricoAvaliacaoModal .historico .loading-historico {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.HistoricoAvaliacaoModal .historico .historico-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100% !important;
}

.historico .historico-content .historico-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 16px;
    color: rgb(69, 69, 69);
    font-weight: 500;
}

.historico .historico-content .historico-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid rgb(240, 240, 240);
}

.historico .historico-content .historico-item .historico-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.historico .historico-content .historico-item .historico-item-content .historico-item-usuario {
    flex: 1;
}

.historico .historico-content .historico-item .historico-item-content .historico-item-data {
    text-align: center;
    font-size: 12px;
    color: rgb(69, 69, 69);
    font-weight: 500;
}

.historico .historico-content .historico-item .historico-item-texto {
    padding-top: 10px;
    font-size: 16px;
    color: rgb(69, 69, 69);
    font-weight: 400;
}

.historico .historico-content .historico-item .historico-item-texto > strong {
    font-size: 14px !important;
    color: rgb(105, 105, 105) !important;
}