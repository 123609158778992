.IndicadorPainelSelect {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: space-between;

    width: 100%;
    margin: 10px 0px;
}

.IndicadorPainelSelect .labelEmpresa {
    font-size: 0.9rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.IndicadorPainelSelect .selects-indicador-painel {
    max-width: 100%;
}

@media (max-width: 767px) {
    .IndicadorPainelSelect .selects-indicador-painel {
        gap: 10px;
    }
}