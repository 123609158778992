.IndicadoresConfig {
    max-width: 100%;
}

.IndicadoresConfig .titleIndicadores {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.IndicadoresConfig .titleIndicadores > .title {
    white-space: nowrap;
    margin-right: 10px;
    
    font-size: 0.9rem;
    font-weight: 500;

    margin-bottom: 5px;
}

.IndicadoresConfig .titleIndicadores > .title-line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
}

.IndicadoresConfig .addIndicador {
    margin: 15px 0px 10px 0px;
    max-width: 100%;
}

.IndicadoresConfig .addIndicador .labelAddIndicador {
    font-size: 0.9rem;
    font-weight: 500;
}

.IndicadoresConfig .listIndicadores {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0px 10px 0px;
    gap: 10px;
}

.IndicadoresConfig .GroupIndicadores {
    margin: 15px 0px 10px 0px;
    max-width: 100%;
    width: 100%;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
    display: flex;
    gap: 10px;
    padding: 5px 0px;

    margin-left: -5px;
    padding-left: 5px;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding: 10px;
    border-radius: 8px;
    background-color: #a3a3a3;
    color: white;
    gap: 10px;
    
    width: 18rem;
    max-width: 90%;
    min-width: 15rem;
    min-height: 12rem;

    cursor: pointer;
    user-select: none;

    font-size: 0.95em;
    
    position: relative;

    transition: box-shadow 0.5s ease, font-size 0.3s ease, transform 0.2s ease;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores.drag-enter {
    border: 2px dashed #000;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores.new {
    background-color: #c3c3c3;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores:hover {
    box-shadow: rgba(50, 50, 50, 0.25) 1px 1px 10px 0px;
    font-size: 1em;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores:active {
    transform: scale(0.99);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores .iconAdd {
    font-size: 1.7rem;
}

.IndicadoresConfig .GroupIndicadores .listGroupIndicadores .groupIndicadores:hover .iconAdd {
    font-size: 1.75rem;
}

.IndicadoresConfig .groupIndicadores .count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.IndicadoresConfig .groupIndicadores.drag-enter .count {
    display: none;
}

.IndicadoresConfig .groupIndicadores .count .number {
    font-size: 3em;
    font-weight: bold;
    margin: -15px 0px;
}

.IndicadoresConfig .groupIndicadores .some {
    display: none;
}

.IndicadoresConfig .groupIndicadores.drag-enter .some {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #505050;
}

.IndicadoresConfig .groupIndicadores .some .number {
    font-size: 3em;
    font-weight: bold;
    margin: -15px 0px;
}

.IndicadoresConfig .groupIndicadores .line {
    width: 2rem;
    height: 2px;
    background-color: white;
}

.IndicadoresConfig .groupIndicadores .title {
    text-align: center;
    font-weight: 600;
}

.IndicadoresConfig .groupIndicadores .iconPosition {
    position: absolute;
    top: 10px;
    left: 10px;
}

.IndicadoresConfig.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.IndicadoresConfig.notUseIndicadores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

