.fatoresSwotCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: black;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: not-allowed;

    box-shadow: none;
    background-color: #BCBCBC;
    transition: background-color 0.2s ease;
    min-height: 15vh;
    height: 15vh;
}

.fatoresSwotCard:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.fatoresSwotCard > .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    overflow: hidden; /* Garante que o conteúdo não transborde */
    flex-grow: 1;
    text-align: center;
}

.fatoresSwotCard > .board {
    display: flex;
    justify-content: start;
    padding: 2px;
    height: 35%;
}

.fatoresSwotCard:hover > .board {
    display: none;
}

.fatoresSwotCard > .board > .quadrante {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 5px;
    color: white;
}

.fatoresSwotCard .barWarning {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 0 5px;
    gap: 5px;
}