.containerBody {
    height: 100%;
    max-height: 100%;
    overflow: auto;
}

.containerBody > .containerConfigChart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    flex-wrap: wrap;
    gap: 5px;
}

.containerBody > .containerConfigChart > .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    flex-wrap: wrap;
    gap: 5px;
}

.containerBody > .containerConfigChart > .line > .left {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    gap: 5px;
}

.containerBody > .containerConfigChart .label {
    font-size: 0.75rem;
    font-weight: 300;
    color: black;
}

.containerBody > .containerConfigChart > .line > .left > .buttonFilter {
    border-radius: 8px;
    color: white;
    height: 43px;
    width: 43px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 3px;
    position: relative;

}

.buttonFilter .indicadorFiltro {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 0.9rem;
    height: 0.9rem;
    background-color: red;
    border-radius: 50%;
    z-index: 1;
    font-size: 0.7rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerBody > .containerChart {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: auto;
    width: 100%;
    flex: 1;
}

.line-chart {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* min-height: 3rem; */
    width: 100%;
}

.line-chart.line-header {
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 100;
}

.line-chart.line-header::after {
    content: "";
    height: calc(100% + 1px);
    width: calc(100% + 1px);
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
    z-index: -1;
}

.line-chart > .cel-chart {
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    min-width: 6rem;
    padding: 2px;
}

.line-chart > .cel-chart.header-cel {
    background-color: rgb(220, 220, 220);
    font-size: 0.6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.line-chart > .cel-chart.value-chart {
    color: white;
    font-weight: 800;
    cursor: default;
    font-size: 1rem;
}

.line-chart > .cel-chart.value-chart:hover {
    box-shadow: rgba(50, 50, 50, 0.7) 1px 1px 10px 0px;
    z-index: 1;
}

.header-cel .info {
    width: 1rem;
    height: 1rem; 
    border-radius: 50%; 
    background-color : black; 
    color: white; 
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    margin-left: 0.5rem;

}

.containerBody .fixed-icon {
    position: absolute;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    
    bottom: 5px;
    right: 5px;
    z-index: 1;
    font-size: 0.8rem;
    gap: 5px;
}


