.box-History {
    width: 100%;
    height: 100%;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-top: 5px;
}

.box-History > .butons-History {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.box-History > .grafico-History {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.box-History > .grafico-History .grafico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc(100% - 200px);
    height: 100%;
}

.box-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.controller {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    height: 100%;
    max-height: 300px;
    padding: 5px;
    border-radius: 5px;
    background-color: #d5d5d5;
}

.controller > .controller-title {
    border-bottom: 0.5px solid #808080;
    text-align: center;
    max-height: calc(100% - 95px);
}

.controller > .controller-title > .title {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: bold;
}