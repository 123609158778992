.participante-ata-card{
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    margin: 10px 0;
    border: 1px solid #a5a5a5;
    border-radius: 8px;
}

.participante-ata-card .form-participante{
    width: 90%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.participante-ata-card .switch-container{ 
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #a5a5a5;
    width: auto;
    border-radius: 5px;
    max-width: max-content;
    height: 35px;
}

.participante-ata-card .switch-container .ata-card-participante-switch{
    margin-left: 5px;
    margin-right: 5px;
}

.participante-ata-card .switch-container .right-icon{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 28px;
}

.participante-ata-card .switch-container .right-icon .mail-icon{
    position: absolute;
    left: 30%;
    top: 0; 
    width: 15px;
    font-size: 0.4rem;
}

.participante-ata-card .switch-container .left-icon{
    width: 28px;
}

.participante-ata-card .participante-select{
    min-width: 200px;
    flex: 1;
}

.participante-ata-card .participante-permissao-select{
    min-width: 100px;
    flex: 1;
}

.participante-ata-card .participante-funcao-select{
    min-width: 100px;
    flex: 1;
}

.participante-ata-card .participante-nome-input{
    flex: 1;
    min-width: 150px;
}

.participante-ata-card .participante-email-input{
    flex: 1;
    min-width: 150px;
}

.participante-ata-card .participante-remover{
    flex: 0.1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
