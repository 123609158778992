.objetivoCard {
    width: 100%;
    font-size: 15px;
    color: black;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #BCBCBC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.objetivoCardNotSelected {
    box-shadow: none;
    background-color: #BCBCBC;
    transition: background-color 0.2s ease;
}

.objetivoCardNotSelected:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #a9a9a9;
}

.objetivoCardSelected {
    box-shadow: none;
    background-color: #B2D9E1;
    transition: background-color 0.2s ease;
}

.objetivoCardSelected:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #a3c8d0;
}