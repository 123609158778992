.BoxCreateRelatorio.box {
    display: flex;
    flex-direction: column;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.BoxCreateRelatorio.box .title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.BoxCreateRelatorio.box .title .info {
    font-size: 1rem;
    cursor: pointer;
}

.BoxCreateRelatorio.box .footer {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}
