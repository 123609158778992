
.graphic-performance {
    width: 45px;
    height: 45px; 
    border-radius: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: fadeIn 0.7s;
    color: white;
    font-weight: bold;
  }

.graphic-meta-realizado {
    position: relative; 
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #ededed;
    cursor: pointer;
    /* margin-top: 16px; */
    color: white;
    font-weight: bold;
}

.graphic-meta-realizado > .graphic-meta {
    position: absolute;
    min-width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    border: none;
    background-color: transparent;
    border-right: 2px solid #4d4d4d;
  }

.graphic-meta-realizado > .graphic-realizado {
    position: absolute;
    min-width: fit-content;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    background-color: transparent;
    font-size: 12px;
}

.animateWidthMetaRealizado {
    animation-name: widthAnimation;
    animation-duration: 1s; /* Ajuste a duração conforme necessário */
}

@keyframes widthAnimation {
    from { width: 0; }
    to { width: 100%; }
}
  
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.errorObjetivoEstrategico {
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorObjetivoEstrategico > .circle {
    min-width: 30px;
    width: 30px;
    min-height: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffc107;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    padding: 3px;
}

