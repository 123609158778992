.RelatorioCard.relatorio {
    padding: 15px 10px;
    margin-bottom: 10px;
    width: 100% !important;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.RelatorioCard.relatorio:hover {
    background-color: #f5f5f5;
}

.RelatorioCard.relatorio-disabled {
    cursor: not-allowed !important;
}

.RelatorioCard.relatorio-disabled:hover {
    background-color: white !important;
}

.RelatorioCard.relatorio > .line {
    display: flex;
    justify-content: space-between ;
    width: 100%;
    margin-bottom: 10px;
}