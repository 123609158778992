.user-tooltip *{
  line-height: 100%;
  margin: 0;
}

.user-tooltip {
  background-color: white !important;
  color: black !important;
  height: 180px;
  width: 350px;
  min-height: 180px;
  min-width: 350px;
  padding: 0;
  overflow: hidden;
}

.user-tooltip .popover-body {
  padding: 0;
  width: 100%;
  height: 100%;
} 

.user-tooltip .user-card-tooltip {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
}

.user-tooltip .user-card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 80px;
}

.user-tooltip  .user-card-header-avatar {
  margin-top: 40px;
}

.user-tooltip .user-card-header-text {
  padding-left: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  width: calc(100% - 65px);
}

.user-tooltip .user-card-header-text p,
.user-tooltip .user-card-header-text h4 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transform: translateX(0);
  transition: 4s;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@keyframes sweep {
  0% {
      transform: translate(10%, 0);
  }
  100% {
      transform: translate(-100%, 0);
  }
}

.user-tooltip .user-card-header-text p:hover,
.user-tooltip .user-card-header-text h4:hover {
  overflow: visible;
  text-overflow: unset;
  animation: sweep 4s linear infinite;
}


.user-tooltip .user-card-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.user-tooltip .user-card-body p {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}