.ConfigEscala {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 100%;
    height: 100%;
}

.ConfigEscala .blocked {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;

    background-color: #d74d4e;
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: default;
}

.ConfigEscala > .config-escala {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 38px - 16px);
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 5px 10px;
    border: 0.1px solid #c0c0c0;
}

.ConfigEscala .label {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
}

.ConfigEscala .label .label-text {
    white-space: nowrap;
    font-size: 1.2rem;
    color: #000;
    margin: 0 1rem 0 0;
    font-weight: 400;
}

.ConfigEscala .label .label-line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
}

