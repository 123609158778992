.modalIndicadores-mobile {
    display: block;
    position: fixed;
    right: 15px;
    bottom: 15px;
    bottom: 100px;
    z-index: 9999;
    width: 95vw;
    padding: 20px 20px 10px 20px;
    border: 0.8px solid lightgrey;
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    height: fit-content !important;
    min-height: 400px;
    max-height: 100%;
}

.modalIndicadores {
    display: block;
    position: fixed;
    right: 15px;
    bottom: 15px;
    bottom: 5px;
    z-index: 9999;
    width: 40vw;
    min-width: 400px;
    padding: 20px 20px 10px 20px;
    border: 0.8px solid lightgrey;
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    height: fit-content !important;
    min-height: 400px;
    max-height: 100%;
}

.modalIndicadores .modalIndicadores-content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    overflow-x: auto;
    padding: 10px 10px 0px 10px;
}

.modalIndicadores-mobile .modalIndicadores-content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    overflow-x: auto;
    padding: 10px 10px 0px 10px;
}

.modalIndicadores-content .loading-modalIndicadores {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.modalIndicadores-content .header-modalIndicadores-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}

.modalIndicadores-content .header-modalIndicadores-title-objetivo {
    display: flex;
    align-items: flex-start;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.modalIndicadores-content .header-modalIndicadores-title {
    display: flex;
    align-items: flex-start;
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
}

.modalIndicadores-content .content-modalIndicadores-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
}

.modalIndicadores-content .content-modalIndicadores-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modalIndicadores-content .content-modalIndicadores-content {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
}

.modalIndicadores-content .line {
    width: 100%;
    height: 1px;
    margin: 10px 0px;
}

.modalIndicadores-content .close-button {
    z-index: 5;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 5px;
    cursor: pointer;
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}