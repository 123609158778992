.ConfigTabela {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    padding: 5px;
}

.ConfigTabela .table-content {
    width: 100% !important;
    border-radius: 8px;
}

.ConfigTabela .table-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.ConfigTabela .table-row.table-row-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow: hidden;
}

.ConfigTabela .table-row.table-row-header .table-header {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
    width: 100%;
    background-color: #f0f0f0;
}

.ConfigTabela .table-row.table-row-header .table-header:first-of-type {
    border-top-left-radius: 8px;
}

.ConfigTabela .table-row.table-row-header .table-header:last-of-type {
    border-top-right-radius: 8px;
}

.ConfigTabela .table-row.table-row-body {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
}

.ConfigTabela .table-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.ConfigTabela .table-cell {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.80rem;
    font-weight: 400;
    border: 1px solid #ddd;
    padding: 2.5px 5px;
    transition: all 0.2s ease;
}

.ConfigTabela .table-cell.hover {
    background-color: #f0f0f0;
    font-size: 0.85rem;
    font-weight: 500;
}

.ConfigTabela .table-cell .edit-icon, .lock-icon, .info-icon {
    position: absolute;
    top: 1px;
    right: 1px;
    display: none;
    cursor: pointer;
}

.ConfigTabela .table-cell:hover .edit-icon.hover-action {
    display: block;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.ConfigTabela .table-cell:hover .lock-icon.hover-action {
    display: block;
    background-color: #d74d4e;
    color: #ffffff;
    border-radius: 50%;
    padding: 5px; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

.ConfigTabela .table-cell:hover .info-icon.hover-action {
    display: block;
    background-color: #000;
    color: #ffffff;
    border-radius: 50%;
    padding: 5px; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.ConfigTabela .table-cell .plus-icon {
    background-color: #59a63d;
    color: #ffffff;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
}

.ConfigTabela .table-cell .plus-icon:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Banner para adicionar Coluna */

.ConfigTabela > .content-banner {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;

    width: 100%;
    flex: 1;
}

.ConfigTabela > .content-banner .collapse-content {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 100%;
    background: white;
    z-index: 10;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow: hidden;
    border-radius: 8px;
    padding: 10px 10px 10px 10px;

    max-width: calc(100% - 20px);
}

.ConfigTabela > .content-banner .collapse-content .form-banner {
    overflow-y: auto;
    overflow-x: hidden;
}

.ConfigTabela > .content-banner .collapse-content .lineDivisor {
    border-bottom: 1px solid #838383;
    height: 1px;
    width: 100%;
}

.ConfigTabela > .content-banner .collapse-content .list-indicadores {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    gap: 5px;
}

.ConfigTabela > .content-banner .collapse-content .indicador-referencia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 5px 5px 5px 10px;
    border: 0.3px solid lightgray;
    border-radius: 8px;
    background-color: #f0f0f0;
}

.ConfigTabela > .content-banner .collapse-content .indicador-referencia .action {
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
}

.ConfigTabela > .content-banner .collapse-content .indicador-referencia:hover .action {
    opacity: 1;
}

.ConfigTabela > .content-banner .collapse-content .headerClose {
    width: calc(100% + 20px);
    height: 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -10px -10px 10px -10px;
    padding: 0px 10px;
}

/* Tela de tabela vazia */

.ConfigTabela > .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}