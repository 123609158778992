.listTiposContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.listTipos {
    height: 100%;
    max-width: 100%;
    margin: 0px -10px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row; 
    align-items: center;
    flex-shrink: 0;

    gap: 10px;
    flex-wrap: nowrap; /* Prevent items from wrapping */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.listTipos > .tipo {

    border-radius: 8px;
    height: 100%;
    max-height: 38px;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap; /* Prevent text from wrapping */
}

.listTipos > .tipo:hover {
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
}