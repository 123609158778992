.RelatorioTabelaResultadoItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;
    min-height: 200px;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    background-color: white;
    position: relative;
}

.RelatorioTabelaResultadoItem .miniLoading 
{
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 3px;
}

.RelatorioTabelaResultadoItem .headerTabelaResultado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -10px;
    padding-bottom: 5px;
    background-color: white;

    font-size: 1.2rem;
    color: black;
    font-weight: bold;
}

.RelatorioTabelaResultadoItem .headerTabelaResultado.pin-header {
    transition: all 0.5s ease;
    position: relative !important;
    top: 0;
    z-index: 10;
    width: calc(100% + 20px);
}

.RelatorioTabelaResultadoItem .headerTabelaResultado.pin-header::before
{
    content: "";
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    z-index: -1;
    box-shadow: 0px -5px 5px 1px rgba(50, 50, 50, 0.1);
}

.RelatorioTabelaResultadoItem .headerTabelaResultado.pin-header::after
{
    content: "";
    height: 100%;
    width: calc(100% + 2px);
    position: absolute;
    top: 0px;
    left: -1px;
    background-color: var(--background-color);
    z-index: -2;
}

.RelatorioTabelaResultadoItem .matrizContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    flex: 1;
    overflow: auto;
    overflow-y: hidden;
    border-radius: 8px;
}

.RelatorioTabelaResultadoItem .line-chart {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: unset !important;
    min-width: 100% !important;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    position: relative;
}

.RelatorioTabelaResultadoItem .line-chart.last-line-chart {
    border-bottom: 1px solid #ccc;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}


.RelatorioTabelaResultadoItem .line-chart.line-header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.RelatorioTabelaResultadoItem .line-chart.line-header::after {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #ccc;
    border-bottom: unset;
}

.RelatorioTabelaResultadoItem .line-chart.pin-header {
    width: 100%;
    transition: all 0.5s ease;
    position: relative;
    top: 0;
    z-index: 10;
}

.RelatorioTabelaResultadoItem .line-chart.pin-header::after
{
    content: "";
    height: calc(100% + 1px);
    width: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
    z-index: -1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #ccc;
    border-bottom: unset;
}

.RelatorioTabelaResultadoItem .line-chart.pin-header::before
{
    content: "";
    height: calc(100% + 1px);
    width: calc(100% + 3px);
    position: absolute;
    top: -1px;
    left: -2px;
    background-color: white;
    z-index: -2;
}

.RelatorioTabelaResultadoItem .line-chart > .cel-chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    min-width: 5rem;
    padding: 2px;
    position: relative;
}

.RelatorioTabelaResultadoItem .line-chart > .cel-chart.header-cel-coluna {
    min-width: 250px;
    text-align: left;
}

.RelatorioTabelaResultadoItem .line-chart > .cel-chart.header-cel {
    background-color: rgb(220, 220, 220);
    font-size: 0.8rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RelatorioTabelaResultadoItem .line-chart > .cel-chart.header-cel .fixed-icon-right{
    margin-right: 8px;
    gap: 5px;
    display: flex;
}


/* Específicos para a lista */

.RelatorioTabelaResultadoItem .table-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.RelatorioTabelaResultadoItem .table-row.table-row-body {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
}

.RelatorioTabelaResultadoItem .table-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.RelatorioTabelaResultadoItem .table-cell {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.80rem;
    font-weight: 400;
    border: 0.1px solid #ddd;
    padding: 2.5px 5px;
    transition: all 0.2s ease;
}

.RelatorioTabelaResultadoItem .table-cell.no-border {
    border: none;
}

.RelatorioTabelaResultadoItem .table-cell.hover {
    background-color: #f0f0f0;
    font-size: 0.85rem;
    font-weight: 500;
}

/* Célula de resultado */

.RelatorioTabelaResultadoItem .value-chart {
    color: white;
    font-weight: 800;
    cursor: default;
    font-size: 1rem;
}

.RelatorioTabelaResultadoItem .value-chart:hover {
    box-shadow: rgba(50, 50, 50, 0.7) 1px 1px 10px 0px;
    z-index: 1;
}

.RelatorioTabelaResultadoItem .value-chart .fixed-icon-right {
    position: absolute;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    
    bottom: 5px;
    right: 5px;
    z-index: 1;
    font-size: 0.8rem;
    gap: 5px;
}

.RelatorioTabelaResultadoItem .value-chart .fixed-icon-left {
    position: absolute;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    
    bottom: 5px;
    left: 5px;
    z-index: 1;
    font-size: 0.8rem;
    gap: 5px;
}