.rotating {
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotateClockwise {
    animation: rotateCounterClockwise 1s linear;
}

@keyframes rotateCounterClockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.react-datepicker-wrapper {
    width: 100% !important;
}