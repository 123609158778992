.selectWidth {
    min-height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.selectWidth > .itemWidth {
    height: 38px;
    width: 60px;
    border-radius: 5px;
    background-color: #D5D5D5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.selectWidth > .itemWidth-hover {
    background-color: #a4a4a4 !important;
}

.selectWidth > .itemWidth.selected {
    background-color: #808080;
    color: white;
}