.plano-acao-etapa-card:hover {
    background-color: #F3F3F3 !important;
}

.plano-acao-etapa-card.warning {
    background-color: #f3d91345 !important;
}

.icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.icon.warning{
    color: #f2b809 !important;
}

.icon.atrasado {
    color: #f20909 !important;
    font-size: 1.2rem;
}

.icon.atrasado-concluido {
    color: #f2b809 !important;
    font-size: 1.2rem;
}

.icon.dependencias {
    font-size: .85rem;
    margin-left: .5rem;
    color: #878787;
}