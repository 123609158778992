.ModalCorrespondencia .titleModal {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 15px;

    font-size: 1.3rem;
    font-weight: 500;
    color: #606060;
    margin-bottom: 1.5rem;
    margin-left: 5px;
}

.ModalCorrespondencia .bodyModal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    flex: 1;
    width: 100%;
    overflow-y: auto;
}

.ModalCorrespondencia .bodyModal .labelBody {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.ModalCorrespondencia .bodyModal .labelBody .title {
    white-space: nowrap;
    margin-right: 10px;

    font-size: 0.9rem;
    font-weight: 500;
}

.ModalCorrespondencia .bodyModal .labelBody .title-line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
}

.ModalCorrespondencia .bodyModal .configIndicador {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 10px;

    font-size: 0.9rem;
    font-weight: bold;
    color: #333;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.ModalCorrespondencia .bodyModal .configIndicador .titleFaixas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    cursor: pointer;
}

.ModalCorrespondencia .bodyModal .configIndicador .titleFaixas .iconFaixa {
    margin-left: 10px;
    font-size: 1rem;
    color: #333;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.ModalCorrespondencia .bodyModal .configIndicador .titleFaixas .iconFaixa.rotate {
    transform: rotate(180deg);
}

.ModalCorrespondencia .bodyModal .configIndicador .lineConfig {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;
}

.ModalCorrespondencia .bodyModal .configIndicador .startConfig {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 2rem;
}

.ModalCorrespondencia .bodyModal .configIndicador .startConfig .configFaixas {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 100%;
    gap: 10px;
}

.ModalCorrespondencia .bodyModal .configIndicador .startConfig .configFaixas .configFaixa {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.ModalCorrespondencia .bodyModal .configIndicador .startConfig .configFaixas .configFaixa .colorName {
    height: 50px;
    width: 35%;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalCorrespondencia .bodyModal .configIndicador .startConfig .configFaixas .configFaixa .valores {
    height: 50px;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    width: calc(65%);

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.ModalCorrespondencia .bodyModal .configIndicador .startConfig .inputDate {
    font-size: 1em;
    color:#333;
    font-weight: bold;
    box-shadow: none;
    cursor: pointer;
    padding: 1px;
    height: 30px;
    text-align: center;
    z-index: 1;
    background-color: #BCBCBC;
}

.ModalCorrespondencia .bodyModal .configIndicador .endConfig {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    gap: 2rem;
    margin: 0px 1rem;
}

.ModalCorrespondencia .bodyModal .listSelects {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    gap: 5px;
    width: 100%;
}

.ModalCorrespondencia .bodyModal .listSelects .itemEmpresa {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
    margin-bottom: 5px;

    gap: 5px;
    width: 100%;

    padding: 0px 10px;

    border-radius: 8px;
    background-color: #f0f0f0;

    transition: background-color 0.3s;
}

.ModalCorrespondencia .bodyModal .listSelects .itemEmpresa:hover {
    background-color: #e5e5e5;
}