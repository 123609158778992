.label {
    font-size: 12px;
    font-weight: bold;
    color: #fafafa;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    margin-left: 8px;
    white-space: nowrap;
}

.label-info {
    background-color: #5bc0de;
}

.label-warning {
    background-color: #f0ad4e;
}