.Avaliacoes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 100vh;
    padding: 7px;
}

/* ----- header da tela ----- */

.Avaliacoes > .Avaliacoes-header {
    margin: 0px 0px 0px 0px;
    padding: 10px;
    background-color: white;
    border: 0.3px solid lightgrey;
    border-radius: 8px;
    width: 100%;
    gap: 5px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Avaliacoes > .Avaliacoes-header > .header-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    flex-wrap: wrap;
}

.Avaliacoes > .Avaliacoes-header > .header-right {

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

/* ----- Container do questionario ----- */

.Avaliacoes > .Avaliacoes-container-padding {
    z-index: 0;
    flex: 1;
    overflow: auto;
    width: calc(100% + 14px);
    padding: 7px;
}

.Avaliacoes > .Avaliacoes-container-padding > .Avaliacoes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    width: 100%;
    height: 100%;

    background-color: white;
    border-radius: 8px;
    /* padding: 10px; */
}

.Avaliacoes .Avaliacoes-container {
    gap: 8px;
    padding-top: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    height: 100%;
}

.Avaliacoes-container .avaliacao-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    align-items: center;
    padding: 10px;
}

.Avaliacoes-container .avaliacao-card .avaliadores{
    gap: 5px;
    min-width: 160px;
}

.Avaliacoes-container .avaliacao-card .avaliadores .button-edit-avaliadores {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.Avaliacoes-container .avaliacao-card .avaliadores:hover .button-edit-avaliadores {
    opacity: 1;
}

.Avaliacoes-container .avaliacao-card .nome-questionario {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
}

.Avaliacoes-container .avaliacao-card .itens-avaliacao {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    min-height: 50px;
}

.Avaliacoes-container .avaliacao-card .status-avaliacao {
    width: 180px;
}

.Avaliacoes-container .avaliacao-card .tipo-avaliacao {
    width: 150px;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .Avaliacoes-container .avaliacao-card .status-avaliacao {
        width: 150px;
    }

    .Avaliacoes-container .avaliacao-card .itens-avaliacao {
        flex-wrap: wrap;
    }

    .Avaliacoes-container .avaliacao-card .avaliadores{
        min-width: unset;
    }

    .Avaliacoes-container .avaliacao-card .status-avaliacao {
        width: auto;
        min-width: 180px;
    }
}

.Avaliacoes-container .clickable {
    cursor: pointer;
}

.Avaliacoes-container .clickable:hover {
    transform: scale(100.5%);
    transition: all 0.3s ease;
}