.iniciativaCardFundo {
    width: 100%;
    min-height: 14vh;
    flex-shrink: 0;
    color: black;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #a9a9a9;
  }
  
  .iniciativaCardFundo > .iniciativaCard {
    height: 100%;
    color: black;
    padding: 5px;
    border-radius: 8px;
    transition: width 0.3s linear;
  }
  
  .iniciativaCardSelected {
    background-color: #B2D9E1;
  }
  
  .iniciativaCardNotSelected {
    background-color: #BCBCBC;
  }

  .barWarning {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 0 5px;
    gap: 5px;
}