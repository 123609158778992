.loading {
    padding: 10px;
    padding-bottom: 20px;
}

.containerEficacia {
    padding: 10px 20px;
    margin: 0px;
    width: 100%;

    .formGroup {
        margin-bottom: 0.8rem;
        font-size: 14px;
    }

    .contentActions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

    .label {
        font-size: 12px;
        font-weight: 300; 
    }

    .value {
        font-size: 14px;
        font-weight: 600;
    }
}