.ProjetoGestaoView_headerCard {
    background-color: #fff;
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
    margin-bottom: 1px;
    border-radius: 8px 8px 0px 0px;
    padding: 15px 15px;
    width: 100%;
    gap: 10px;
}

.ProjetoGestaoView_headerCard > .loading {
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProjetoGestaoView_headerCard > .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 5px;
    font-size: 14px;
    font-weight: 200;
}

.ProjetoGestaoView_headerCard > .column .title {
    font-size: 20px;
    font-weight: 500;
}

.ProjetoGestaoView_headerCard > .column > .subTitle {
    font-size: 14px;
    font-weight: 400;
}

.ProjetoGestaoView_headerCard > .column > .colaboradores {
    margin-top: 5px;
}

.ProjetoGestaoView_headerCard > .column > .status {
    display: flex;
    justify-content: center;
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: start;
}

.ProjetoGestaoView_headerCard > .column > .date {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.ProjetoGestaoView_headerCard > .column > .metodologia {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.ProjetoGestaoView_headerCard > .column > .priorizacao {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.ProjetoGestaoView_descricao {
    background-color: #fff;
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 1px;
}

.ProjetoGestaoView_graficos {
    background-color: #fff;
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 1px;
}

.ProjetoGestaoView_indicadores {
    background-color: #fff;
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
    border-radius: 0px 0px 8px 8px;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
}

.ProjetoGestaoView_detalhamento {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 5px;
}