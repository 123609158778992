.ProcessoCard {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center; /* Adicionado para garantir a mesma altura */
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
    margin-top: 10px;
}

.ProcessoCard > .card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    align-items: stretch; /* Adicionado para garantir a mesma altura */
}

.ProcessoCard > .card-top > .card-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
    width: 60%;
}

.ProcessoCard > .card-top > .card-left > .title {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: start;
    color: #333;
}

.ProcessoCard > .card-top > .card-left > .title.loading {
    height: 1.2rem;
    width: 100%;
    background-color: lightgrey;
    border-radius: 8px;
}

.ProcessoCard > .card-top > .card-left > .tag-info {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    background-color: lightgrey;
    border-radius: 8px;
    font-size: 0.9rem;
    padding: 5px 10px;
    margin-bottom: 0.5rem;
}

.ProcessoCard > .card-top > .card-left > .tag-info.create {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    background-color: #FFB200;
    border-radius: 8px;
    font-size: 0.9rem;
    padding: 5px 10px;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.ProcessoCard > .card-top > .card-left > .tag-info.create:hover {
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
}

.ProcessoCard > .card-top > .card-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    width: 40%;
}

.ProcessoCard > .card-top > .card-right > .date {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background-color: hsl(0, 0%, 90%);
    color: dark;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    gap: 5px;
}

.ProcessoCard > .card-down {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.ProcessoCard > .card-down > .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.ProcessoCard > .card-down > .description > .title {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.ProcessoCard > .card-down > .description > .content {
    width: 100%;
    padding: 5px;
}