.RelatorioObservacao.observacao {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.RelatorioObservacao.observacao > .infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.RelatorioObservacao.observacao > .infos > .info {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
}

.RelatorioObservacao.observacao > .descricao {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid rgb(188, 188, 188);
    width: 100%;
}