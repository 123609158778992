.ModalVincularProjetoMapa.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(20rem + 48px + 43px);
    background-color: #f0f4f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeInScale 0.5s ease-in-out;
}

.ModalVincularProjetoMapa.success-container > .success-title {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    animation: slideIn 0.5s ease-in-out 0.3s forwards;
    opacity: 0;
}

.ModalVincularProjetoMapa.success-container > .success-message {
    font-size: 16px;
    color: #666;
    text-align: center;
    animation: slideIn 0.5s ease-in-out 0.6s forwards;
    opacity: 0;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}