.Questionarios {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 100vh;
    padding: 7px;
}

/* ----- header da tela ----- */

.Questionarios > .questionarios-header {
    margin: 0px 0px 0px 0px;
    padding: 10px;
    background-color: white;
    border: 0.3px solid lightgrey;
    border-radius: 8px;
    width: 100%;
    gap: 5px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Questionarios > .questionarios-header > .header-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    flex-wrap: wrap;
}

.Questionarios > .questionarios-header > .header-right {

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

/* ----- Container do questionario ----- */

.Questionarios > .questionarios-container-padding {
    z-index: 0;
    flex: 1;
    overflow: auto;
    width: calc(100% + 14px);
    padding: 7px;
}

.Questionarios > .questionarios-container-padding > .questionarios-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    width: 100%;
    height: 100%;

    background-color: white;
    border-radius: 8px;
    padding: 10px;
}

/* ----- Header do container do questionario ----- */

.questionarios-container > .questionarios-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    margin-bottom: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.questionarios-container > .questionarios-options > .isEditing {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #e3e3e3;
    padding: 5px 10px;
    border-radius: 8px;
    height: 38px;
}

.questionarios-container > .questionarios-options > .isEditing > .animated-ellipsis::after {
    content: '';
    display: inline-block;
    animation: ellipsisAnimation 1.2s steps(4, end) infinite;
    min-width: 1em;
}

@keyframes ellipsisAnimation {
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '';
    }
}

.questionarios-container > .questionarios-options > .list-questionarios {
    display: flex;
    flex-direction: row;
    justify-content: start;
    max-width: 100%;
    flex-shrink: 0;
    gap: 10px;
    overflow-x: auto;
}

.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    min-height: 38px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: bold;
    background-color: #808080;
    color: white;
    gap: 10px;
    transition: all 0.5s ease;
    white-space: nowrap;
}

.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option:hover {
    background-color: #A0A0A0;
}

/* ----- Lista seletora de questionario ----- */

/* - Default display none - */
.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option > .icon-delete {
    display: none;
}

.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option > .icon-locked {
    display: none;
}

/* - Habilita o display conforme sua classe de permissão - */
.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option.locked > .icon-locked {
    display: flex;
    opacity: 0;
}

.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option.delete> .icon-delete {
    display: flex;
    opacity: 0;
}

/* - Hover de opacidade - */
.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option.delete:hover > .icon-delete {
    display: flex;
    opacity: 1;
    transition: all 0.7s ease;
    cursor: pointer;
}

.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option.locked:hover > .icon-locked {
    display: flex;
    opacity: 1;
    transition: all 0.7s ease;
    cursor: not-allowed;
}

.questionarios-container > .questionarios-options > .list-questionarios > .questionarios-option.delete > .icon-delete:hover {
    transform: scale(1.2);
}

