.numerico-picker {
    width:90%;
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.numerico-picker .numerico-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    gap: 10px;
    padding: 10px;
}

.numerico-picker .numerico-container #diagnostico-avaliacao-escala-input {
    display: flex;
    flex-direction: row;

    min-width: 30px;
    width: 10%;
}

.numerico-picker .numerico-container .diagnostico-avaliacao-escala-slider {
    max-width: 90%;
    flex: 1;
    display: flex;
    flex-direction: row;
}