.table-importer-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    position: relative;
}

.table-importer {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.table-importer th,
.table-importer td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    max-width: 300px;
}

.table-importer th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table-importer th,
.table-importer td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}