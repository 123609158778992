.relatorioPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 7px;
    padding-top: 0px;
    height: 100vh;
}

.header {
    padding: 10px;
    width: calc(100% + 5px);
    background-color: white;
    border: 0.3px solid lightgrey;
}

.sticky-header {
    padding: 10px 2.5px !important;
    padding-bottom: 0px !important;
    position: sticky;
    top: 0px;
    z-index: 20;
    border: unset;
}

.header .header-content {
    padding: 10px;
    background-color: white;
    border: 0.3px solid lightgrey;
    border-radius: 8px;
} 

.header .header-content > .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

}

.header .header-content > .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 8px;
    flex-wrap: wrap;
}

.body {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 69px);
}