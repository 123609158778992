.PeriodoAvaliacaoCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch; /* Adicionado para garantir a mesma altura */
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
    margin-top: 10px;
}

.PeriodoAvaliacaoCard > .card-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
    width: 60%;
}

.PeriodoAvaliacaoCard > .card-left > .title {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    
    font-size: 1.2rem;
    font-weight: 500;
    text-align: start;
    color: #333;
    gap: 10px;
}

.PeriodoAvaliacaoCard > .card-left > .title.loading {
    height: 1.2rem;
    width: 100%;
    background-color: lightgrey;
    border-radius: 8px;
}

.PeriodoAvaliacaoCard > .card-left > .tags-infos {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.PeriodoAvaliacaoCard > .card-left > .tags-infos > .tag-example{
    background-color: hsl(0, 0%, 90%);
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 150px;
    min-height: 28px;
}

.PeriodoAvaliacaoCard > .card-left > .avaliadores {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    font-size: 1rem;
    font-weight: 300;
    text-align: start;
    color: #333;
}

.PeriodoAvaliacaoCard > .card-left > .avaliadores.loading {
    height: 1.2rem;
    width: 100%;
    background-color: lightgrey;
    border-radius: 8px;
}

.PeriodoAvaliacaoCard > .card-left > .avaliadores > .empty {
    
    font-size: 0.8rem;
    font-weight: 300;
    text-align: start;
    color: #333;
}

.PeriodoAvaliacaoCard > .card-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    width: 40%;
}

.PeriodoAvaliacaoCard > .card-right > .date-status {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.PeriodoAvaliacaoCard > .card-right > .date-status > .date {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background-color: hsl(0, 0%, 90%);
    color: dark;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    gap: 5px;
}

.PeriodoAvaliacaoCard > .card-right > .date-status > .status {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    color: dark;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
}

@media (max-width: 710px) {
    .PeriodoAvaliacaoCard {
        flex-direction: column;
    }

    .PeriodoAvaliacaoCard > .card-left {
        width: 100%;
    }

    .PeriodoAvaliacaoCard > .card-right {
        width: 100%;
    }
}