.operacao {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    gap: 0px;
    width: max-content;
    max-width: 100%;
    user-select: none;
}

.operacao .add-logica-line {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    cursor: pointer;
    height: 5px;
    z-index: 2;
    transition: opacity 0.3s ease, height 0.3s ease;
}

.operacao .add-logica-line:hover {
    opacity: 1;
    height: 20px;
}

.operacao .add-logica-line:active {
    opacity: 0;
    height: 5px;
}

.operacao .add-logica-line div {
    flex: 1;
    height: 2px;
    background-color: #ccc;
}

.operacao .operacao-logica {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    /* Filhos ocupam 100% da largura do pai */
    background-color: #e3e3e3;
    border-radius: 8px;
    padding: 5px 10px;
}

.operacao .operacao-logica.editing {
    background-color: #c3c3c3;
    box-shadow: rgba(50, 50, 50, 0.4) 1px 1px 10px 0px;
}

.operacao .operacao-logica.loading {
    background: linear-gradient(90deg, #e3e3e3, #d3d3d3, #d3d3d3, #e3e3e3);
    background-size: 200% 100%;
    animation: loadingEffect 2s infinite;
}

@keyframes loadingEffect {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.operacao .operacao-logica .operacao-logica-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    max-width: 100%;
}

.operacao .operacao-logica .operacao-logica-line .operacao-logica-operacao {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 60px;

    font-size: 1.1rem;
    font-weight: 200;
}

.operacao .operacao-logica-condicao {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;


    background-color: white;
    border-radius: 8px;
    padding: 3px 5px;
    gap: 5px;
    position: relative;

    overflow-x: auto;
    overflow-y: none;
}

.operacao .operacao-logica-condicao .item-3ac {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
}

.operacao .operacao-logica-condicao .item-3ac.editing {

    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 30px;

    position: relative;
}

.operacao .operacao-logica-condicao .item-3ac .iconDelete {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 3;
    font-size: 0.75rem;
    border-radius: 50%;
    background-color: #e3e3e3;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #ccc;
}

.operacao .operacao-logica-condicao .item-3ac .iconMove {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 3;
    font-size: 0.75rem;
    border-radius: 50%;
    background-color: #e3e3e3;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #ccc;
    cursor: move;
}

.operacao .operacao-logica-condicao .item-3ac:hover .iconDelete {
    display: flex;
}

.operacao .operacao-logica-condicao .item-3ac:hover .iconMove {
    display: flex;
}

.operacao .operacao-logica-resposta {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;

}

.operacao .operacao-logica-resposta .operacao-logica-resposta-title {
    font-size: 1.1rem;
    font-weight: 200;
}

.operacao .operacao-logica-resposta .operacao-logica-resposta-value {
    font-size: 1.1rem;
    font-weight: 200;
}

.operacao .operacao-logica .buttonCollapse {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 5px;
}

.operacao .operacao-logica .buttonCollapse div {
    flex: 1;
    height: 2px;
    background-color: #ccc;
}