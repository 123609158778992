body {
  margin: 0;
  background-color: transparent !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='range'] {
  /* Change the track background */
  -webkit-appearance: none;
  width: 100%;
  background: linear-gradient(to right, 
                #EEEEEE 20%, 
                #DDDDDD 20%, #DDDDDD 40%, 
                #CCCCCC 40%, #CCCCCC 60%, 
                #AAAAAA 60%, #AAAAAA 80%, 
                #888888 80%, #888888 100%);
}

input[type='range']::-webkit-slider-thumb {
  /* Change the thumb background */
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  /* Change the thumb background for Firefox */
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr td {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
}

table tr th {
  background-color: #ededed;
  border: 1px solid #ddd;
  padding: 10px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: 1em;
}

.form-check-input:checked {
  background-color: #22262E !important;
  border-color: #6668 !important;
}

.hoverMouse {
  cursor: pointer;
  box-shadow: none;
}

.hoverMouse:hover {
  box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
}

.hoverScale {
  cursor: pointer;
}

.hoverScale-disabled {
  cursor: default !important;
  color: lightgrey !important;
}

.hoverScale:hover {
  transform: scale(1.1);
}

.box-advancedSearch {
  background-color: #e4e4e4;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-label-xsm, .form-control-xsm {
  font-size: 13px;

}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-container:hover {
  transform: scale(1.1);
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

@keyframes marquee {
  0%   { transform: translateX(65%); }
  100% { transform: translateX(-100%); }
}

.boxMinhaEquipe {
  transition: transform 0.3s ease;
}

.boxMinhaEquipe:hover {
  transform: scale(1.1);
}

.historicoEtapa p {
  margin: 0;
}

.optionsModalEtapa .dropdown-toggle::after {
  display: none !important; 
}

.optionsProjeto .dropdown-toggle::after {
  display: none !important; 
}

.rotating {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.semiRotating {
  animation: semiRotating 0.3s linear forwards;
}

@keyframes semiRotating {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(180deg);
  }
}

.rotateClockwise {
  animation: rotateCounterClockwise 1s linear;
}

@keyframes rotateCounterClockwise {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(-360deg);
  }
}

#calendar-portal {  
  position: absolute;
  z-index: 100 !important;
}

.optionsModalTarefa .dropdown-toggle::after {
  display: none !important; 
}

.tooltip-inner {
  max-width: 500px !important;
}