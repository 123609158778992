.boxProjetosObjetivo {
    padding: 10px;
    width: 100%;
    transition: height 0.3s ease; /* Adiciona uma transição suave para a altura */
}

.boxProjetosObjetivo > .emptyProjetos {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
    border-radius: 8px;
}

.boxProjetosObjetivo > .gridContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.boxProjetosObjetivo > .gridContainer > .configProjetos {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 5px;
}

.boxProjetosObjetivo > .gridContainer > .space-between {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap; /* Permite quebra de linha quando necessário */
}

.child-config:last-child {
    margin-left: auto; /* Empurra o último filho para o final da linha */
}

.boxProjetosObjetivo > .projetoExpanded {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    height: 18rem;
}

.boxProjetosObjetivo > .projetoExpanded > .historyChart {
    width: calc(100% - 30vw);
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.boxProjetosObjetivo > .projetoExpanded > .gaugeChart {
    width: 30vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.boxProjetosObjetivo > .projetoExpanded > .gaugeChart > .head {
    display: flex;
    justify-content: start;
    height: 1rem;
    width: 100%;
}

.boxProjetosObjetivo > .projetoExpanded > .gaugeChart > .head > .hover {
    cursor: pointer;
}

.boxProjetosObjetivo > .projetoExpanded > .gaugeChart > .head > .hover:hover {
    scale: 1.15;
}

.boxProjetosObjetivo > .projetoExpanded > .gaugeChart > .body {
    display: flex;
    justify-content: start;
    height: calc(100% - 1rem);
    width: 100%;
}

@media (max-width: 750px) {

    .boxProjetosObjetivo .gridContainer > .projetoExpanded {
        flex-direction: column-reverse;
        height: 25rem;
    }

    .boxProjetosObjetivo .gridContainer > .projetoExpanded > .historyChart {
        width: 100%;
        height: 18rem;
    }

    .boxProjetosObjetivo .gridContainer > .projetoExpanded > .gaugeChart {
        width: 100%;
        height: 7rem;
    }
}

.nonPermissionProjetosObjetivo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
    padding: 1rem;
    gap: 1rem;
}




