.GradeAvaliacao {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
}

.GradeAvaliacao > .top-avaliacoes {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.GradeAvaliacao .top-avaliacoes .top-avaliacoes-right {
    display: flex;
    flex-direction: row;
    place-self: flex-end;
    gap: 10px;
}

.GradeAvaliacao > .top-avaliacoes > .button-avaliacoes {
    padding: 6px 12px;
    border-radius: 0.375rem;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 6px;

    cursor: pointer;
    user-select: none;
}

.GradeAvaliacao > .top-avaliacoes > .button-avaliacoes:active {
    transform: scale(1.01);
}

.GradeAvaliacao > .top-avaliacoes > .button-avaliacoes.disabled {
    cursor: block;
    user-select: none;
}

.GradeAvaliacao > .avaliacoes-banner {
    width: 100%;
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 5px 0px
}

.GradeAvaliacao .labelBanner  {
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
}

@keyframes highlight {
    0% { background-color: #fff; }
    50% { background-color: #f0f0f0; }
    100% { background-color: #fff; }
}

.GradeAvaliacao td.editing {
    animation: highlight 1s infinite;
}

.GradeAvaliacao td .button-edit-avaliadores {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.GradeAvaliacao td:hover .button-edit-avaliadores {
    opacity: 1;
}

.ShareModal {
    width: 50vw;
    height: 50%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px !important;
    overflow: hidden !important;
}

.ShareModal .ModalHeader {
    height: 30px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.ShareModal .ModalHeader .title {
    width: calc(100% - 30px);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
}

.ShareModal .ModalHeader .close {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0px;
    user-select: none;
}

.ShareModal .ModalBody {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
}

.ShareModal .ModalBody .share-avaliador-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
}

.ShareModal .ModalBody .share-avaliador-card .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.ShareModal .ModalBody .share-avaliador-card .buttons .mail {
    width: 22px;
    height: 22px;
    display: flex;
    cursor: pointer;
}

.ShareModal .ModalBody .share-avaliador-card .buttons .mail:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.ShareModal .ModalBody .share-avaliador-card .buttons .link {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
}

.ShareModal .ModalBody .share-avaliador-card .link:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

@media screen and (max-width: 768px) {
    .ShareModal {
        width: 90vw !important;
        min-width: 300px !important;
    }
}