.boxIndicadoresObjetivo {
    padding: 10px;
    width: 100%;
    transition: height 0.3s ease; /* Adiciona uma transição suave para a altura */
}

.boxIndicadoresObjetivo > .emptyObjetivo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;
    background-color: #BCBCBC;
    border-radius: 8px;
}

.boxIndicadoresObjetivo > .emptyIndicadores {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
    border-radius: 8px;
}

.boxIndicadoresObjetivo > .gridContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.boxIndicadoresObjetivo > .gridContainer > .configIndicadores {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 5px;
}

.boxIndicadoresObjetivo > .gridContainer > .space-between {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap; /* Permite quebra de linha quando necessário */
}

.child-config:last-child {
    margin-left: auto; /* Empurra o último filho para o final da linha */
}

.child-config > .hover {
    cursor: pointer;
}

.child-config > .hover:hover {
    scale: 1.15;
}

.boxIndicadoresObjetivo > .gridContainer > .configIndicadores > .config-gauge {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 220px;
    gap: 10px;
    margin-bottom: 5px;
}

.boxIndicadoresObjetivo .gridContainer > .indicadorExpanded {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    height: 15rem;
}

.boxIndicadoresObjetivo .gridContainer > .indicadorExpanded > .historyChart {
    width: calc(100% - 20vw);
    height: 100%;
}

.boxIndicadoresObjetivo .gridContainer > .indicadorExpanded > .gaugeChart {
    width: 20vw;
    height: 100%;
}

@media (max-width: 750px) {

    .boxIndicadoresObjetivo .gridContainer > .indicadorExpanded {
        flex-direction: column-reverse;
        height: 25rem;
    }

    .boxIndicadoresObjetivo .gridContainer > .indicadorExpanded > .historyChart {
        width: 100%;
        height: 18rem;
    }

    .boxIndicadoresObjetivo .gridContainer > .indicadorExpanded > .gaugeChart {
        width: 100%;
        height: 7rem;
    }
}

.nonPermissionIndicadoresObjetivo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
    padding: 1rem;
    gap: 1rem;
}






