.RelatorioPerformanceTabelaItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;
    min-height: 200px;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    background-color: white;
    position: relative;
}

.RelatorioPerformanceTabelaItem .miniLoading 
{
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 3px;
}

.RelatorioPerformanceTabelaItem .headerIndicadoresPerformanceTabela {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -10px;
    padding-bottom: 5px;

    font-size: 1.2rem;
    color: black;
    font-weight: bold;
    z-index: 100;
}

.RelatorioPerformanceTabelaItem .headerIndicadoresPerformanceTabela.pin-header {
    
    width: calc(100% + 20px);
    padding-bottom: 2px;
    position: relative;
    top: 0px;
    z-index: 100;
    transition: all 0.5s ease;
}

.RelatorioPerformanceTabelaItem .headerIndicadoresPerformanceTabela.pin-header::before {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: white;
    z-index: -1;
    box-shadow: 0px -5px 5px 1px rgba(50, 50, 50, 0.1);
}

.RelatorioPerformanceTabelaItem .headerIndicadoresPerformanceTabela.pin-header::after {
    content: "";
    width: calc(100% + 1px);
    height: 100%;
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    background-color: var(--background-color);
    z-index: -2;
}


.RelatorioPerformanceTabelaItem .table-container {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
    border-radius: 5px;
}

.RelatorioPerformanceTabelaItem .table-container .indicador-expand-faixas-vertical {
    position: absolute;
    bottom: 0;
    right: calc(50% - 10px);
    width: 20px;
    place-content: center;
    cursor: pointer;
}

.RelatorioPerformanceTabelaItem .table-container .indicador-expand-faixas-horizontal {
    position: absolute;
    right: 0;
    width: 20px;
    place-content: center;
    cursor: pointer;
}

.RelatorioPerformanceTabelaItem .table-container th {
    min-width: 150px;
}

.RelatorioPerformanceTabelaItem .table-container th .expand-faixas-th {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RelatorioPerformanceTabelaItem .table-container table {
    width: 100%;     /* Permite que a tabela se ajuste ao contêiner */
    min-width: 100%; /* Ocupa pelo menos 100% do contêiner */
    table-layout: auto; /* Permite que a largura das colunas se ajustem ao conteúdo */
}

.RelatorioPerformanceTabelaItem td {
    padding: 5px;
    text-align: center;
    font-size: 0.8rem;
    min-width: 80px; /* Define uma largura mínima razoável */
    max-width: 200px; /* Define uma largura máxima */
    word-wrap: normal; /* Alterado: não quebra palavras */
    word-break: normal; /* Alterado: não quebra palavras */
    white-space: normal; /* Permite múltiplas linhas */
    overflow-wrap: break-word; /* Quebra apenas quando necessário */
    hyphens: none; /* Evita hifenização */
}

.RelatorioPerformanceTabelaItem thead.sticky-header{
    position: relative;
    background-color: #f3f3f3;
    z-index: 105;
    top: 0;
    transition: all 0.5s ease;
}
.RelatorioPerformanceTabelaItem thead.sticky-header::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 1px);
    background-color: #dee2e6;
    z-index: -1;
}

.RelatorioPerformanceTabelaItem .sticky-left-header-column {
    position: sticky;
    left: 0;
    z-index: 102;
}

.RelatorioPerformanceTabelaItem .sticky-left-header-column::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100%);
    height: calc(100%);
    background-color: #f3f3f3;
    z-index: -1;
}

.RelatorioPerformanceTabelaItem .sticky-left-header-column::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-color: #dee2e6;
    z-index: -2;
}

.RelatorioPerformanceTabelaItem th {
    padding: 5px;
    text-align: center;
    font-size: 0.8rem;
    min-width: 80px;
    max-width: 200px;
    word-wrap: normal; /* Alterado: não quebra palavras */
    word-break: normal; /* Alterado: não quebra palavras */
    white-space: normal; /* Permite múltiplas linhas */
    overflow-wrap: break-word; /* Quebra apenas quando necessário */
    hyphens: none; /* Evita hifenização */
}

.RelatorioPerformanceTabelaItem .modal-subtitle {
    font-size: 0.9rem;
    color: #606060;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.RelatorioPerformanceTabelaItem td {
    display: table-cell !important;
}

.RelatorioPerformanceTabelaItem tr {
    display: table-row !important;
    margin-bottom: unset !important;
}

.RelatorioPerformanceTabelaItem thead {
    display: table-header-group !important;
}

.RelatorioPerformanceTabelaItem tbody {
    display: table-row-group !important;
}