
/* do it only for mozzilla */
@-moz-document url-prefix() {
    .avaliacao-scrollbar * {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
    }
}
/* Chrome, Edge and Safari */
.avaliacao-scrollbar *::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
.avaliacao-scrollbar *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}
  
.avaliacao-scrollbar *::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.avaliacao-scrollbar *::-webkit-scrollbar-track:active {
    background-color: rgba(0, 0, 0, 0.2);
}

.avaliacao-scrollbar *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
}

.avaliacao-scrollbar *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.avaliacao-scrollbar *::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.3);
}