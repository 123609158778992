.RelatorioPerformanceItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;
    min-height: 200px;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    background-color: white;
    position: relative;

    overflow: hidden;
}

.RelatorioPerformanceItem .miniLoading 
{
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 105;
}

.RelatorioPerformanceItem .headerIndicadoresPerformance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -10px;
    margin-bottom: 5px;

    font-size: 1.2rem;
    color: black;
    font-weight: bold;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 100;
}

.RelatorioPerformanceItem .headerIndicadoresPerformance.pin-header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: calc(100% + 20px);
    position: relative;
    z-index: 100;
    transition: all 0.5s ease;
}

.RelatorioPerformanceItem .headerIndicadoresPerformance.pin-header::before {
    content: "";
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
    height: calc(100% + 10px);
    top: -5px;
    left: 0;
    position: absolute;
    background-color: white;
    z-index: -1;
    box-shadow: 0px -5px 5px 1px rgba(50, 50, 50, 0.1);
}

.RelatorioPerformanceItem .headerIndicadoresPerformance.pin-header::after {
    content: "";
    width: calc(100% + 1px);
    height: calc(100% + 10px);
    position: absolute;
    top: -10px;
    left: -0.5px;
    background-color: var(--background-color);
    z-index: -2;
}

.RelatorioPerformanceItem .grupoIndicadores {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    height: auto;

    border-radius: 8px;
    /* background-color: #f3f3f3; */
    /* border: 1px solid #e3e3e3; */
}

.RelatorioPerformanceItem .grupoIndicadores .headerFaixa {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    width: 100%;
    padding: 5px;
    gap: 5px;

    color: white;

    font-size: 0.75rem;
    font-weight: bold;

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.RelatorioPerformanceItem  .grupoIndicadores .headerFaixa.pin-header {
    border: 0.5px solid #ccc;
    border-bottom: unset;
    position: relative;
    z-index: 100;
    transition: all 0.5s ease;
}

.RelatorioPerformanceItem .grupoIndicadores .headerFaixa.pin-header::before {
    content: "";
    width: 16px;
    border-top-left-radius: 8px;
    height: 100%;
    position: absolute;
    left: -2px;
    top: 0;
    border-left: 0.5px solid #ccc;
}

.RelatorioPerformanceItem .grupoIndicadores .headerFaixa.pin-header::after {
    content: "";
    width: 16px;
    border-top-right-radius: 8px;
    height: 100%;
    position: absolute;
    right: -2px;
    top: 0;
    border-right: 0.5px solid #ccc;
}

.RelatorioPerformanceItem .grupoIndicadores .headerFaixa .infoVisualizacao {
    font-size: 0.75rem;
    font-weight: 500;
    margin-right: 5px;
}

.RelatorioPerformanceItem .grupoIndicadores .headerNome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 100%;
    padding: 0px;

    color: rgb(0, 0, 0);
    border-left: 0.5px solid transparent;
    border-right: 0.5px solid transparent;
    border-bottom: 0.5px solid #ccc;

    font-size: 1.1rem;
    font-weight: bold;
}

.RelatorioPerformanceItem  .grupoIndicadores .headerNome.pin-header {
    position: relative;
    z-index: 100;
    transition: all 0.5s;
}

.RelatorioPerformanceItem  .grupoIndicadores .headerNome.pin-header::before {
    content: "";
    width: 0.5px;
    height: 100%;
    position: absolute;
    left: -2px;
    top: 0;
    background-color: #ccc;
}

.RelatorioPerformanceItem  .grupoIndicadores .headerNome.pin-header::after {
    content: "";
    width: 0.5px;
    height: 100%;
    position: absolute;
    right: -1px;
    top: 0;
    background-color: #ccc;
}

.RelatorioPerformanceItem .grupoIndicadores .bodyCharts {
    display: grid;
    gap: 5px;
    padding: 5px 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.RelatorioPerformanceItem .grupoIndicadores .bodyCharts.donut {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.RelatorioPerformanceItem .grupoIndicadores .bodyCharts.gauge {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.RelatorioPerformanceItem .grupoIndicadores .bodyCharts .chartBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RelatorioPerformanceItem .grupoIndicadores .footerCalculo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 100%;
    padding: 0px;
    gap: 5px;
    border-top: 0.5px solid #ccc;
    background-color: #f3f3f3;

    font-size: 0.65rem;
    font-weight: bold;
}