.avaliacao-questao-card {
    display: flex;
    flex-direction: column;
    place-content: center;
    padding: 20px; 
    margin: 10px; 
    height: auto;
}

.avaliacao-questao-card .questao {
    display: flex;
    flex-direction: row;
    place-content: center;
    width: 100%;
}

.avaliacao-questao-card .questao-descricao {
    display: flex; 
    padding-right: 20px; 
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    flex: 1;
    border-right: 1px solid darkgrey;
}

.avaliacao-questao-card .questao-descricao h2 {
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin: 0px !important;
}

.avaliacao-questao-card .questao-resposta {
    display: flex;
    place-content: center;
    width: 40%;
    max-width: 600px;
    min-width: 350px;
    padding-left: 10px;
}

.avaliacao-questao-card .questao-icons {
    padding-left: 10px;
    display: flex;
    place-content: center;
    align-items: center;
    gap: 10px;
}

.avaliacao-questao-card .questao-adicionais-collapse{
    transition: all 0.5s ease;
}

.avaliacao-questao-card .questao-adicionais-button {
    height: 30px;
    width: 100%;
    display: flex;
    place-content: center;
    align-items: center;
}

.avaliacao-questao-card .questao-adicionais-container {
    display: flex;
    width: 100%;
    min-height: 100px;
    transition: all 0.5s ease;
}


.avaliacao-questao-card .questao-adicionais-container .questao-adicionais {
    display: flex;
    flex-direction: row;
    place-content: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
}

.avaliacao-questao-card .questao-adicionais-container .questao-adicionais .questao-observacao {
    display: flex;
    place-content: center;
    width: 60%;
    flex: 1;
}

.avaliacao-questao-card .questao-adicionais-container .small .questao-observacao {
    padding-right: 0px !important;
    width: 100% !important;
    padding-bottom: 20px !important;
}

.avaliacao-questao-card .questao-adicionais-container .questao-adicionais .questao-uploads {
    display: flex;
    place-content: center;
    width: 40%;
    max-width: 600px;
    min-width: 350px;
    padding-left: 10px;
}

.avaliacao-questao-card .questao-adicionais-container .small .questao-uploads {
    padding-left: 0px !important;
    width: 100% !important;
    min-width: 100% !important;
    padding-top: 20px !important;
}

.avaliacao-questao-card .small {
    flex-direction: column !important;
}

.avaliacao-questao-card .small .questao-descricao {
    border-right: none !important;
    padding-right: 0px !important;
    width: 100% !important;
    padding-bottom: 20px !important;
    border-bottom: 1px solid darkgrey !important;
}

.avaliacao-questao-card .small .questao-resposta {
    width: 100% !important;
    min-width: 100% !important;
    padding-left: 0px !important;
    padding-top: 20px !important;
}

.avaliacao-questao-card .questao-resposta .star-selector {
    display: flex;
    place-content: center;
    width: 100%;
}

.avaliacao-questao-card .small .questao-icons {
    padding-left: 0px !important;
    width: 100% !important;
    padding-top: 20px !important;
}