.cardPlanejamento {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.cardPlanejamento .emptyPlanejamento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.cardPlanejamento > .titlePlanejamento {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 10px;
    height: 40px;
    width: 100%;
}

.cardPlanejamento > .loadingPlanejamento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px);
}

.cardPlanejamento > .bodyPlanejamento {
    padding: 5px;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.bodyPlanejamento > .perspectivaPlanejamento{
    padding: 5px 0px;
    width: 100%;
    overflow-y: auto;
}

.perspectivaPlanejamento > .nomePerspectiva {
    display: flex;
    align-items: center;
    color: #808080;
    margin-bottom: 10px;
    font-weight: 500;
}

.perspectivaPlanejamento > .nomePerspectiva > .line {
    flex-grow: 1;
    margin: 5px 0px 5px 10px;
    border: none;
    border-top: 2px solid;
}

.perspectivaPlanejamento > .listObjetivos {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    max-width: 100%;
    overflow: auto;
    gap: 10px;
    padding: 5px 5px 0px 5px;
}

.perspectivaPlanejamento > .collapseDetalhamento {
    width: 100%;
    padding: 0px 5px;
    transition: all 0.5s;
}
