.Relatorio {
    padding: 7px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

@media (max-width: 680px) {
    .Relatorio {
        padding-bottom: 100px;
    }
}

/* ===== HEADER ===== */

.Relatorio>.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
    border: none;
    gap: 5px;
    background-color: rgb(255, 255, 255) !important;
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.Relatorio>.header>.line {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    gap: 10px;
}

@media (max-width: 680px) {
    .Relatorio>.header>.line {
        flex-wrap: wrap;
    }
}

.Relatorio>.header>.line .nomeRelatorio {
    font-size: 1.2em;
    font-weight: bold;
}

.Relatorio>.header>.line>.subTitle {
    width: 100%;
    font-size: 0.9em;
    color: #666;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Relatorio>.header>.line>.infos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Relatorio>.header>.line>.infos>.info {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

/* ===== RECOMENDAÇÕES ===== */

.Relatorio>.recomendacoes {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.Relatorio>.recomendacoes>.titleRecomendacoesLine {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;


}

.Relatorio>.recomendacoes>.titleRecomendacoesLine>.titleRecomendacoes {
    font-size: 1.2em;
    font-weight: bold;
}

.Relatorio>.recomendacoes>.contentRecomendacoes {
    border-radius: 8px;
    width: 100%;
    border: 0.3px solid #ccc;
    padding: 10px 10px 0px 10px;
    margin-bottom: 10px;
    gap: 15px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.Relatorio>.recomendacoes .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: start;
    align-items: start;
    gap: 10px;
}

.Relatorio>.recomendacoes .content .node {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.Relatorio>.recomendacoes .content .description {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    width: 100%;
}

/* ===== OBSERVAÇÕES ===== */

.Relatorio>.observacoes {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.Relatorio>.observacoes > .titleObservacoesLine {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.Relatorio>.observacoes > .titleObservacoesLine > .titleObservacoes {
    font-size: 1.2em;
    font-weight: bold;
}

.Relatorio>.observacoes .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: start;
    align-items: start;
    gap: 10px;
}

.Relatorio>.observacoes .content .node {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.Relatorio>.observacoes .content .description {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    width: 100%;
}

.relatorio-publish-confirm {
    z-index: 10000 !important;
}