.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
    content: "32 pixels";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
    content: "24 pixels";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
    content: "18 pixels";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before {
    content: "16 pixels";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before {
    content: "12 pixels";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before {
    content: "10 pixels";
}

.ql-mention-list-container {
    width: 365px !important;
    height: 180px;
}

.ql-mention-list-item.selected {
    background-color: hsl(0, 0%, 90%) !important;
}

.mention {
    background-color: hsl(0, 0%, 90%) !important;
}

.plain-text-editor-quill .ql-editor{
    padding: 5px;
}

.plain-text-editor-quill .ql-container{
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 1.2;
    letter-spacing: 0.5px;
}

.bigger-placeholder-editor .ql-editor::before {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.5px;
}

.fill-container-editor {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
}

.fill-container-editor .ql-toolbar {
    width: 100%;
    height: auto;
}

.fill-container-editor .ql-container {
    width: 100%;
    flex: 1;
}