.relatorio-pa table {
    border-collapse: separate;
    border-spacing: 0;
}

.relatorio-pa table th,
.relatorio-pa table td,
.relatorio-pa table tr,
.relatorio-pa table thead,
.relatorio-pa table tbody{
    border: unset;
    outline: unset;
    padding: 0;
}

@media screen and (max-width: 600px) {
    .relatorio-pa table {
        width: 100%;
    }

    .relatorio-pa table th,
    .relatorio-pa table td,
    .relatorio-pa table tr,
    .relatorio-pa table thead,
    .relatorio-pa table tbody {
        all: revert;
    }
}

@media screen and (min-width: 1000px) {
    .sticky-column {
        position: sticky;
    }
}

.progress-bar-tabela .progress-bar{
    background-color: black;
    min-width: 0px;
}