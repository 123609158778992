.ModuloConfig {
    width: 100%;
    min-width: 100px; /* Define a largura mínima */
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #D5D5D5;
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: relative; /* Required for the left resize handle */
}

.ModuloConfig::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px; /* Width of the resize handle */
    cursor: ew-resize; /* Cursor for resizing horizontally */
}


.ModuloConfig > .headerClose {
    width: calc(100% + 20px);
    height: 3rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -10px -10px 5px -10px;
    padding: 0px 10px;
    text-align: center;
}

.ModuloConfig .bodyModulo  {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    padding-top: 10px;

    overflow-y: auto;
    gap: 10px;
}

.ModuloConfig .bodyModulo .content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
}

.ModuloConfig .bodyModulo .content .text {
    width: 100%;
}

.ModuloConfig .bodyModulo .content span {
    display: flex;
    justify-content: start;
    gap: 5px;
    font-weight: 300;
    color: #505050;
    font-size: 0.8rem;
    margin-bottom: 5px;
    font-weight: bold;
}

.ModuloConfig .bodyModulo .content .createdChartGroup {
    display: flex;
    max-width: 100%;
    min-height: 4rem;
    overflow-x: auto;
    justify-content: start;
    gap: 5px;
    flex-wrap: nowrap; /* Garante que os itens não quebrem linha */
}

.ModuloConfig .bodyModulo .content .table-container {
    max-width: 100%;
    overflow-x: auto;
}

.ModuloConfig .bodyModulo .content .table-results {
    width: auto; /* Permite que a tabela cresça conforme necessário */
    min-width: 100%; /* Garante que a tabela ocupe pelo menos 100% da largura do contêiner */
}

.ModuloConfig .bodyModulo .content .rowHeader {
    border: 1px solid #3e3e3e;
    border-radius: 5px;
    gap: 1px;
    overflow: hidden;
}

.ModuloConfig .bodyModulo .content .rowHeader .cellHeader {
    background-color: white;
    color: #3e3e3e;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: visible; /* Permite que o conteúdo da célula seja visível */
    text-overflow: clip; /* Remove a elipse de truncamento */
    
}

.ModuloConfig .bodyModulo .content .rowHeader .cellHeader:first-of-type {
    border-top-left-radius: 5px;
    /* border-bottom-left-radius: 5px; */
}

.ModuloConfig .bodyModulo .content .rowHeader .cellHeader:last-of-type {
    border-top-right-radius: 5px;
    /* border-bottom-right-radius: 5px; */
}

.ModuloConfig .bodyModulo .content .cell.result {
    background-color: #FFB200;
    color: white;
    font-weight: 1000;
    font-size: 1rem;
}

.ModuloConfig .bodyModulo .box {
    border-radius: 5px;
    padding: 5px;
    background-color: #eeeeee;
    border: #d3d3d3 1px solid;
}

.ModuloConfig .bodyModulo .buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
    gap: 5px;
    width: 100%;
}

/* Layout dos itens adicionados aos módulos (Gráficos e Performance) */

.ModuloConfig .item-added {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #D5D5D5;
    border-radius: 5px;
    padding: 5px;
    gap: 5px;
    cursor: move;
    width: 'auto';
    min-width: 10rem;
    height: 4rem;
    flex-shrink: 0;
    user-select: none;
}

.ModuloConfig .item-added .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.ModuloConfig .item-added .chart-name {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    font-size: 0.8rem;
}

.ModuloConfig .item-added .chart-name > .column {
    font-size: 0.8rem;
}

.ModuloConfig .item-added .chart-name > .description {
    font-size: 0.6rem;
    max-width: 180px;
    text-align: center;
}

.ModuloConfig .item-added .icon-drag {
    font-size: 1rem;
    cursor: move;
    color: #333333;
    opacity: 0; /* Default transparent state */
    transition: opacity 0.3s ease; /* Smooth transition when opacity changes */
}

.ModuloConfig .item-added:hover .icon-drag {
    opacity: 1; /* Fully visible on hover */
}

.ModuloConfig .item-added .icon-delete {
    font-size: 1rem;
    cursor: pointer;
    color: #d74d4e
}

.ModuloConfig .item-added .bar-grupo {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 20px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid hsl(0, 0%, 90%);
}

.ModuloConfig .item-added .bar-grupo .bar-grupo-valor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 3px;

    color: white;
    font-size: 10px;
    font-weight: bold;
}