.ModalResumoNodeQuestionario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    z-index: 9999 !important;
    position: relative;
    width: 90vw;
}

.ModalResumoNodeQuestionario .miniLoading 
{
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 3px;
}

.ModalResumoNodeQuestionario .labelEmpty {
    font-size: 1.1em;
    color: #000;
    margin: 0;
    padding: 0;
}

.ModalResumoNodeQuestionario .labelTitle {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5px;

    font-size: 0.8em;
    color: #000;
    font-weight: bold;
}

.ModalResumoNodeQuestionario .bodyNode {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    height: 100%;

    overflow-y: auto;
    gap: 15px;
    padding: 5px;

    /* flex: 1; */
    
}

.ModalResumoNodeQuestionario .listFilhos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
    gap: 10px;
    width: 100%;
}

.ModalResumoNodeQuestionario .listFilhos .itemFilho {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 60px;
    height: 60px;
    padding: 0px 0px 0px 5px;
    gap: 10px;

    background-color: #f3f3f3;
    border: 0.1px solid #d5d5d5;
    border-radius: 5px;

    font-size: 0.9rem;
    overflow: hidden;
}

.ModalResumoNodeQuestionario .listFilhos .itemFilho:hover {
    box-shadow: rgba(50, 50, 50, 0.2) 1px 1px 10px 0px;
}

.ModalResumoNodeQuestionario .hiddenID {
    opacity: 0;
    background-color: black;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 5px;
    cursor: default;
}

.ModalResumoNodeQuestionario .hiddenID:hover {
    opacity: 1;

}

.ModalResumoNodeQuestionario .value-resposta {

    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    min-width: 5rem;
    max-width: 15rem;
    padding: 2px 5px;

    color: white;
    font-weight: 800;
    cursor: default;
    font-size: 1rem;

    border-radius: 5px;
}
