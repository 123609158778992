.retro-gradient {
    background: linear-gradient(
        to bottom,
        #6a11cb, #2575fc,
        #43cea2, #185a9d
    );
    background-size: 100%;
}

.loading-gradient {
    background: linear-gradient(
        to bottom,
        #6a11cb, #2575fc
    );
    background-size: 100%;
}

.retro-intro-text {
    padding-top: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #E8E8E4;
    text-align: center;
    margin-top: 1rem;
    max-width: 80%;
}

.retro-default-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: #E8E8E4;
    text-align: justify;
    margin-top: 1rem;
    max-width: 80%;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    animation: bounce 3s infinite;
}

@keyframes slide-in-y {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in {
    animation: slide-in-y 2s;
}

@keyframes shine {
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
}

.shine {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.3)
    );
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
}