.graficosPlano {
    padding: 5px;
}

.border {
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    overflow: hidden;
}

.graficosPlano .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graficosPlano > .graficoOrcamento {
    height: 50px;
    padding: 0 5px;
}