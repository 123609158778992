.StarPicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.StarPicker > .star-title {
    text-align: start;
    margin-bottom: 1rem;
}

.StarPicker > .star-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.StarPicker > .star-content .stars {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Permite que as estrelas quebrem linha se necessário */
}

.StarPicker > .star-content .star {
    font-size: 3rem;
    color: #d0d0d0;
    cursor: pointer;
    flex-grow: 1; /* Permite que as estrelas cresçam para preencher o espaço disponível */
    max-width: calc(100% / var(--star-count)); /* Ajuste dinâmico baseado no número de estrelas */
}

.StarPicker > .star-content .star-disabled {
    cursor: default;
}

.StarPicker > .star-content .star.hover {
    color: #d9ba5e;
}

.StarPicker > .star-content .star.active {
    color: #EFB810;
}

.StarPicker > .star-content .star:hover {
    color: #d9ba5e;
}

