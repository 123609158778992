@keyframes pulse-star {
    0% {
      color: rgba(0, 0, 0, 0.3);
    }
    50% {
      color: rgba(0, 0, 0, 0.5);
    }
    100% {
      color: rgba(0, 0, 0, 0.3);
    }
  }
.star-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    height: auto;
    width: auto;
    padding: 0;
    margin: 0;
}

.star-button .star {
    font-size: 1.5em;
}

.star-button .star-loading {
    animation: pulse-star 1.5s ease-in-out infinite !important;
}
