.boxDetalhamentoObjetivo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    /* border: 1px solid #e0e0e0; */
    box-shadow: 0px 0px 5px #b8b8b8;
    background-color: #f7f7f7;
    border-radius: 8px;
}

.boxDetalhamentoObjetivo > .titleDetalhamentoObjetivo {
    display: flex;
    justify-content: end;
    align-items: center;
    width: calc(100% - 10px);
    /* border-bottom: 1px solid #e0e0e0; */
    margin: 5px 5px;
    height: 40px;
}

.boxDetalhamentoObjetivo > .bodyDetalhamentoObjetivo {
    display: flex;
    justify-content: start;
    width: 100%;
    height: calc(100% - 40px);
}