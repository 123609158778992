.RelatorioTextItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
    padding: 15px 10px;
    border-radius: 8px;
}

.RelatorioTextItem > .texto {
    width: 100%;
    padding: 10px;
}